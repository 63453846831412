html {

    // split
    #lds-classes {
        background: 0 68px white repeat-x url(../../images/general/table-bg.png); 
        background-size: 100px 1276px; 
        width: 100%; 
        height: 332px;

        .lds-spriteleft,
        .lds-spriteright {
            background: 0 -72px no-repeat url(../../images/wellness/wellness.png);
            background-size: 1440px 1024px; 
            width: 820px; 
            height: 332px;
        }
        .lds-spriteright {
            background-position: -874px -72px;
            width: 200px;
            min-width: 200px; // make it stick
        }
    }

    // #lds-classes222 {
    //     background: 0 -72px no-repeat url(../../images/wellness/wellness.png);
    //     background-size: 1440px 1024px; 
    //     width: 1440px; 
    //     height: 600px;
    // }

    // #lds-videos-list {
    //     background: 0 -72px no-repeat url(../../images/videos/videos-list.png);
    //     background-size: 1440px 1024px; 
    //     width: 1440px; 
    //     height: 600px;
    // }

    // #lds-videos-details {
    //     background: 0 -72px no-repeat url(../../images/videos/videos-details.png);
    //     background-size: 1440px 1178px; 
    //     width: 1440px; 
    //     height: 600px;
    // }




} // html