
html#lds-html-demos-patientpanel1 {
    height: 100% !important;
    // background: repeat-y white url(../../images/demos/bottommargin1/body-bg.png); 
    overflow: hidden;
    // border: red 1px solid;
    // padding-top: 72px;

    // body
    > body {
        height: 100% !important;
    }


    .lds-patientpanel1 {

        // border: red 1px solid;

        &-row {
            position: absolute;
            top: 88px;
            left: 0;
            height: 43px;
            // width: 4000px;
            width: 4000px;
            // border: red 1px solid;
            // background-color: red;
            cursor: pointer;
            background-color: rgba(0,0,0,.01);
            border: red 1px solid;

            // background: no-repeat url(../../images/demos/patientpanel1/patientpanel-row.png); 
            // background-size: 600px 43px; 
            // background-color: #E9F6FF;

            // selected
            &.is-selected {
                background: no-repeat url(../../images/demos/patientpanel1/patientpanel-row.png); 
                background-size: 600px 43px; 
                background-color: #E9F6FF;
            }
        }

        &-panel {

            display: flex;
            height: 100%;
            width: 100%;;
            // border: blue 2px solid;
            overflow-x: hidden;
            overflow-y: auto;

            &.is-john .lds-patientpanel1-panel-inner {
                background: no-repeat url(../../images/demos/patientpanel1/patientpanel-panel-alt.png); 
                background-size: 368px 1436px; 
                width: 368px;
                height: 1436px;
            }

            &-inner {
                position: relative;
                background: no-repeat url(../../images/demos/patientpanel1/patient-details-panel.png); 
                background-size: 368px 1572px; 
                width: 368px;
                height: 1572px;

                > * {
                    position: absolute;
                    
                }
    
                // border: red 1px solid;
            }
        }

        .lds-fauxlist-top {

        }


    }

    .lds-fauxlist .lds-fauxsplit.is-type-header > * {
        // border: red 2px solid !important;
        background-image: url(../../images/demos/patientpanel1/patientpanel-list-header.png); 
        background-size: 1393px 112px; 
    }

    .lds-fauxlist .lds-fauxsplit.is-type-header .lds-fauxsplit-left {
        width: 530px;
    }


    .lds-fauxlist .lds-fauxsplit.is-type-rows > * {
        // border: red 2px solid !important;
        background-image: url(../../images/demos/patientpanel1/patientpanel-list-rows.png); 
        background-size: 1393px 704px; 
    }

    .lds-fauxlist.lds-fauxsplit.is-type-rows .lds-fauxsplit-left {
        width: 600px;
    }

    // .lds-fauxlist.lds-fauxsplit.is-type-rows {
    //     height: 1186px !important;
    // }

    .lds-fauxlist .lds-fauxsplit.is-type-rows {
        height: 1186px;
    }


    .lds-genlayout-content {
        background-color: white;
    }




}


// html .lds-fauxlist .lds-fauxsplit.is-type-header > * {
//     border: red 2px solid !important;

// }