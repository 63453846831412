html {


    #lds-settings-sidenav {
        background: no-repeat url(../../images/settings/settings-sidenav.png); 
        background-size: 250px 900px; 
        width: 250px; 
        height: 900px;
    }

    #lds-settings-general {
        background: no-repeat url(../../images/settings/settings-general.png); 
        background-size: 1000px 802px; 
        width: 1000px; 
        height: 802px;
    }

    #lds-settings-corporate {
        background: no-repeat url(../../images/settings/settings-corporate.png); 
        background-size: 1000px 2059px; 
        width: 1000px; 
        height: 2059px;
    }



} // html