html body { // HTML




    
.lds-icon {

    &.is-type-circle {background-position: 0 0px;}
    &.is-type-circle.is-selected {background-position: 0 -30px;}

    &.is-type-chevron {background-position: -30px 0px;}
    &.is-type-chevron.is-selected {background-position: -30px -30px;}

    &.is-type-checkbox {background-position: -60px 0px;}
    &.is-type-checkbox.is-selected {background-position: -60px -30px;}

    &.is-type-radio {background-position: -90px 0px;}
    &.is-type-radio.is-selected {background-position: -90px -30px;}

    &.is-type-comment {background-position: -120px 0px;}
    &.is-type-comment.is-selected {background-position: -120px -30px;}

    &.is-type-phone {background-position: -150px 0px;}
    &.is-type-phone.is-selected {background-position: -150px -30px;}

    &.is-type-inbox {background-position: -180px 0px;}
    &.is-type-inbox.is-selected {background-position: -180px -30px;}

    // standard bookmark (not red)
    &.is-type-bookmark {background-position: -210px 0px;}
    &.is-type-bookmark.is-selected {background-position: -210px -30px;}

    &.is-type-alert-circle {background-position: -240px 0px;}
    &.is-type-alert-circle.is-selected {background-position: -240px -30px;}

    &.is-type-task {background-position: -270px 0px;}
    &.is-type-task.is-selected {background-position: -270px -30px;}

    &.is-type-recurring {background-position: -300px 0px;}
    &.is-type-recurring.is-selected {background-position: -300px -30px;}

    &.is-type-archive {background-position: -330px 0px;}
    &.is-type-archive.is-selected {background-position: -330px -30px;}

    &.is-type-trash {background-position: -360px 0px;}
    &.is-type-trash.is-selected {background-position: -360px -30px;}
    &.is-type-trash.is-color-dark {background-position: -360px -60px;}

    &.is-type-x {background-position: -390px 0px;}
    &.is-type-x.is-selected {background-position: -390px -30px;}

    &.is-type-check {background-position: -420px 0px;}
    &.is-type-check.is-selected {background-position: -420px -30px;}

    // tasks bookmarked (red)
    &.is-type-bookmarked {background-position: -450px 0px;}
    &.is-type-bookmarked.is-selected {background-position: -450px -30px;}

    // tasks status
    &.is-type-taskstatus {background-position: -480px 0px;}
    &.is-type-taskstatus.is-selected {background-position: -480px -30px;}

    &.is-type-resolved {background-position: -480px 0px;}
    &.is-type-resolved.is-selected {background-position: -480px -30px;}

    &.is-type-user {background-position: -510px 0px;}
    &.is-type-user.is-selected {background-position: -510px -30px;}

    // alerts
    &.is-type-alert-primary {background-position: -540px 0px;}
    &.is-type-alert-error {background-position: -540px -30px;}
    &.is-type-alert-warning {background-position: -540px -60px;}
    &.is-type-alert-success {background-position: -540px -90px;}

    // alerts
    &.is-type-alert {background-position: -570px 0px;}
    &.is-type-alert.is-selected,
    &.is-type-alert.is-color-blue {background-position: -570px -30px;}
    &.is-type-alert.is-color-dark {background-position: -570px -60px;}
    &.is-type-alert.is-color-red {background-position: -570px -90px;}

    // shop
    &.is-type-shop {background-position: -600px 0px;}
    &.is-type-shop-white {background-position: -600px -90px;}

    // eye
    &.is-type-eye {background-position: -630px 0px;}
    &.is-type-eye-primary {background-position: -630px -30px;}
    &.is-type-eye-dark {background-position: -630px -60px;}
    &.is-type-eye-white {background-position: -630px -90px;}

    // external
    &.is-type-external {background-position: -660px 0px;}
    &.is-type-external.is-selected,
    &.is-type-external-primary {background-position: -660px -30px;}
    &.is-type-external-dark {background-position: -660px -60px;}
    &.is-type-external-white {background-position: -660px -90px;}

    // search
    &.is-type-search {background-position: -690px 0px;}
    &.is-type-search.is-selected,
    &.is-type-search-primary {background-position: -690px -30px;}
    &.is-type-search-dark {background-position: -690px -60px;}
    &.is-type-search-white {background-position: -690px -90px;}

    // globe
    &.is-type-globe {background-position: -720px 0px;}
    &.is-type-globe.is-selected,
    &.is-type-globe-primary {background-position: -720px -30px;}
    &.is-type-globe-dark {background-position: -720px -60px;}
    &.is-type-globe-white {background-position: -720px -90px;}

    // bell
    &.is-type-bell {background-position: -750px 0px;}
    &.is-type-bell.is-selected,
    &.is-type-bell-primary {background-position: -750px -30px;}
    &.is-type-bell-dark {background-position: -750px -60px;}
    &.is-type-bell-white {background-position: -750px -90px;}

    // lock
    &.is-type-lock {background-position: -780px 0px;}
    &.is-type-lock.is-selected,
    &.is-type-lock-primary {background-position: -780px -30px;}
    &.is-type-lock-dark {background-position: -780px -60px;}
    &.is-type-lock-white {background-position: -780px -90px;}

    // editor
    &.is-type-editor {background-position: -810px 0px;}
    &.is-type-editor.is-selected,
    &.is-type-editor-primary {background-position: -810px -30px;}
    &.is-type-editor-dark {background-position: -810px -60px;}
    &.is-type-editor-white {background-position: -810px -90px;}

    // lumistry
    &.is-type-lumistry {background-position: -840px 0px;}

    // lumistry
    &.is-type-progress-incomplete {background-position: -870px 0px;}
    &.is-type-progress-current {background-position: -870px -30px; border-radius: 100px; outline: #D1E0FF 4px solid;}
    &.is-type-progress-complete {background-position: -870px -60px;}
}



        
        
        
} // HTML