// --- container --- //

#lds-html-demos-topnav1 {
    min-width: 1280px;

    .MuiTooltip-popper div {
        // border: red 2px solid !important;
        padding: 8px 12px;
        background-color: black;
        // font-size: 12px;;
    }
}

#lds-body-demos-topnav1 {

    padding-top: 72px;
}

#lds-body-demos-topnav1-container {
    background-color: white !important;
}

// --- top nav 2 --- //


html .lds-topnav1b {

    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    z-index: 10;
    height: 72px;
    width: 100%;
    min-width: 1280px; // --- NEW
    // max-width: 1280px;
    background: repeat-x url(../../images/toolbars/topnav-bg.png);

    &-leftside {
        display: flex;
        // border: red 1px solid;
        // background-color: yellow;
    }

    &-tab {
        display: inline-block;
        height: 72px;
        padding: 24px 16px 0;
        color: #CEECFF;
        cursor: pointer;
        text-decoration: none;

        // flex-wrap: nowrap;
        white-space: nowrap;

        // modifiers

        &.is-selected {
            background-color: #0093E8;
            color: white;
        }

        &.is-numbered .lds-topnav1b-number {
            display: inline-block;
        }
    }

    &-number {
        // display: inline-block;
        display: none;
        border-radius: 11px;
        height: 22px;
        min-width: 22px;
        font-size: 12px;
        line-height: 18px;
        color: #374D58;
        background-color: #FFC854;
        text-align: center;
        padding: 2px 4px 0;
        margin-left: 10px;
    }



    &-middle {
        display: flex;
        flex-grow: 1;
        // background-color: green;

        justify-content: flex-end;
        align-items: center;

        overflow: hidden;// --- THIS WORKS

        .lds-menu {
            width: 100%;
            max-width: 350px;
            // background-color: red;
        }

        padding: 20px 0 20px 20px;

        &-input {
            height: 40px;
            background-color: gray;
            width: 100%;
            max-width: 350px;
        }

    }

    &-context {
        // background-color: #01529F !important;
        background: #01529F no-repeat url(../../images/demos/topnav1/menuicon.png);
        background-size: 34px 20px;
        background-position: right center;

        border-radius: 8px;
        width: 100%;
        height: 46px;
        max-width: 100%;
        padding: 14px 40px 14px 20px;
        // overflow: hidden;

        &-text {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: white;
            // width: 100%;
        }
    }

    &-rightside {
        display: flex;
        // background-color: blue;
        background: top right url(../../images/toolbars/topnav.png);
        background-size: 1440px 72px;
        width: 220px;
        min-width: 220px;
        height: 72px;

    }

}

// --- top nav 1 --- //

.lds-topnav1 {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    height: 72px;
    width: 100%;
    min-width: 1320px; // GLOBAL MIN WIDTH
    background: repeat-x url(../../images/toolbars/topnav-bg.png);
    background-size: 20px 72px;

    // // modifiers
    // &.is-brand {
    //     .lds-topnav1b-context {
    //         span {
    //             padding-left: 44px;
    //         }
    //     }
    // }

    // members
    &-demo {
        position: absolute;
        left: auto;
        right: 0;
        display: inline-block;
        font-size: 13px;
        padding: 2px;
        color: white;
        background-color: rgb(255, 0, 0);
       //  width: 100px;
        z-index: 100;
    }

    &-logo {
        display: inline-block;
        position: relative;
        top: 0;
        left: 0;
        width: 90px;
        height: 72px;
        vertical-align: top;

        i {
            position: absolute;
            top: 10px;
            left: 20px;
        }
    }

    &-context {

        .lds-icon {
            position: absolute;
            top: 10px;
            left: 12px;
        }
        
        span {
            display: block;
            padding: 8px 0 0 18px;
            background-color: #00529f;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            color: white;
            font-size: 14px;
            font-family: Lato;
            line-height: 24px;
            font-weight: 400;
            // opacity: .5;
            width: 200px;
            border-radius: 8px;
        }

    }

    // &-middle {
    //     // --- WIP
    //     // background-color: green;
    //     // width: 100%;
    //     border: green 5px solid;
    // }

    &-right {
        position: absolute;
        top: 0;
        left: auto;
        right: 0;
        background: top right url(../../images/toolbars/topnav.png);
        background-size: 1440px 72px;
        width: 480px;
        height: 72px;
        > * {
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    &-tab {
        display: inline-block;
        height: 72px;
        padding: 24px 16px 0;
        color: #CEECFF;
        cursor: pointer;
        text-decoration: none;

        &.is-selected {
            background-color: #0093E8;
            color: white;
        }
    }

    &-number {
        display: inline-block;
        border-radius: 11px;
        height: 22px;
        min-width: 22px;
        font-size: 12px;
        line-height: 18px;
        color: #374D58;
        background-color: #FFC854;
        text-align: center;
        padding: 2px 4px 0;
        margin-left: 10px;
    }
}



// .lds-topnav-noauth {
//     &-left,
//     &-right {
//         background: no-repeat top left url("../../images/toolbars/topnav-noauth.png");
//         background-size: 1440px 72px;
//         width: 300px;
//         height: 72px;
//     }
//     &-right {
//         background-position: top right;
//     }
// }





// --- list --- //

#lds-topnav1-list {
    background: 0 68px white repeat-x url(../../images/general/table-bg.png); 
    background-size: 100px 1276px; 
    width: 100%; 
    height: 816px;

    .lds-spriteleft,
    .lds-spriteright {
        background: repeat-x url(../../images/refills/refills.png); 
        background-size: 1072px 816px; 
        width: 750px; 
        height: 816px;
    }
    .lds-spriteright {
        background-position: top right;
        width: 200px;
        min-width: 200px; // make it stick
    }
}




