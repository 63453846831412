

html .lds-genform {
    position: relative;
    background: repeat-y url(../../images/demo/genform.png); 
    background-size: 768px 957px;
    width: 100%;
    height: 904px; // 957px;

    > * {position: absolute;}

    &-section {
        position: absolute;
        top: 0;
        left: 0;
        // border: blue 2px solid;
        width: 100%;

        > * {position: absolute;}
    }

    // modifiers

    &.is-error {
        // border: red 1px solid;
        background: repeat-y url(../../images/demo/genform-error.png); 
        background-size: 768px 1085px;
        width: 100%;
        height: 1085px; // 957px;
        
        .lds-genform-section.is-name-1 {top: 72px;}
        .lds-genform-section.is-name-2 {top: 100px;}
        .lds-genform-section.is-name-3 {top: 130px;}
        
    }

}

