html { // HTML

    
    .lds-avatar {
        display: block;
        width: 24px;
        height: 24px;
        border-radius: 100px;
        text-align: center;
        padding-top: 4px;
        background-color: #ccc;
        color: #222;

        // modifiers
        &.is-inverse {
            color: white;
        }
        &.is-inline {
            display: inline-block;
        }

        /*
        display: inline-block;
        width: 20px;
        height: 20px;
        background-position: 0 -20px;
        background: no-repeat url(../../images/icons.png); 
        background-size: 800px 140px; 
    
        // modifiers
        &.is-size-sm {
            width: 16px; 
            height: 16px; 
            background: no-repeat url(../../images/icons-sm.png); 
            background-size: 800px 140px; 
        }
        &.is-size-lg {
            width: 24px; 
            height: 24px; 
            background: no-repeat url(../../images/icons-lg.png); 
            background-size: 800px 140px; 
        }
        */
    }
    
    
    
    } // HTML