html {


    #lds-auto {
        background: no-repeat url(../../images/auto/auto.png);
        background-size: 1000px 620px; 
        width: 1000px; 
        height: 620px;
    }

}