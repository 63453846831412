html {

    #lds-tasks-container {
        padding: 0 24px 0 294px;
    }

    #lds-tasks {
        background: 0 101px white repeat-x url(../../images/general/table-bg.png); 
        background-size: 100px 1276px; 
        width: 100%; 
        min-width: 830px;
        height: 761px;

        .lds-spriteleft,
        .lds-spriteright {
            background: repeat-x url(../../images/tasks/tasks-list2.png); 
            background-size: 754px 761px; 
            width: 580px; 
            height: 761px;
        }
        .lds-spriteright {
            background-position: top right;
            width: 170px;
            min-width: 170px; // make it stick
        }
    }

    // --- new
    #lds-tasks.is-altstore,
    #lds-tasks.is-brand {
        height: 365px;
        overflow: hidden; // --- NEW

        .lds-spriteleft,
        .lds-spriteright {
            background: repeat-x url(../../images/tasks/tasks-list2-brand2.png); 
            background-size: 754px 365px; 
            // width: 580px; 
            height: 365px;
        }
        .lds-spriteright {
            background-position: top right;
            width: 170px;
            min-width: 170px; // make it stick
        }
    }

    #lds-tasks.is-altstore {
        height: 541px;

        .lds-spriteleft,
        .lds-spriteright {
            background-image: url(../../images/tasks/tasks-list2-altstore2.png); 
            background-size: 754px 541px; 
            height: 541px;
        }
    }

    // #lds-tasks.is-brand {
    //     height: 453px;

    //     .lds-spriteleft,
    //     .lds-spriteright {
    //         background: repeat-x url(../../images/tasks/tasks-list2-brand.png); 
    //         background-size: 754px 453px; 
    //         height: 453px;
    //     }
    // }



    #lds-tasks-sidenav {
        background: repeat-x url(../../images/tasks/tasks-sidenav.png); 
        background-size: 270px 700px; 
        height: 800px;
    }
    #lds-tasks-sidenav-grid {
        background: repeat-x url(../../images/tasks/tasks-sidenav-grid.png); 
        background-size: 270px 700px; 
        height: 800px;
    }
    #lds-tasks-sidenav-auto {
        background: repeat-x url(../../images/tasks/tasks-sidenav-auto.png); 
        background-size: 270px 700px; 
        height: 800px;
    }

    #lds-tasks-edit-modal {
        background: url(../../images/tasks/tasks-edit-modal2.png); 
        background-size: 768px 1211px; // 1165px; 
        height: 1211px;
    }

    #lds-tasks-grid {
        // background: 0 0 no-repeat url(../../images/tasks/tasks-grid.png); 
        // background-size: 990px 591px; 
        // width: 990px; 
        // height: 591px;
        background: 0 0 no-repeat url(../../images/tasks/tasks-grid2.png); 
        background-size: 990px 412px; 
        width: 990px; 
        height: 412px;
    }

    // new

    #lds-tasks-panel {
        background: no-repeat url(../../images/tasks/tasks-panel.png); 
        background-size: 368px 742px; 
        width: 368px; 
        height: 742px;

        // modifiers
        &.is-selection-2 {
            background: no-repeat url(../../images/tasks/tasks-panel-alt.png); 
            background-size: 368px 881px; 
            height: 881px;
        }

        // members
        &-summary,
        &-title {
            position: absolute;
            top: 235px;
            left: 24px;
            background-color: white;
            width: 280px;
            height: 24px;
            // border: red 2px solid;

            color: #101828;
            font-family: Lato;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 111.111% */
        }

        &-summary {
            top: 106px;
            width: 320px;
            overflow: hidden;
        }
    }

    #lds-tasks-auto {
        background: no-repeat url(../../images/tasks/tasks-auto.png); 
        background-size: 1000px 480px; 
        width: 1000px; 
        height: 480px;
    }


} // html