

html .lds-genlayout {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-width: 1280px;
    overflow-y: hidden;
    overflow-x: auto;
    // border: red 10px solid;


    &-cta {
        // border: red 1px solid;
        // border-radius: 2px;
    }

    &-top {
        height: 72px;
        // background-color: blue;
    }

    &-bottom {
        display: flex;
        height: calc(100% - 72px);
        // background-color: white;
        background: repeat-y white url(../../images/demos/bottommargin1/body-bg.png); 
        // background-color: green;
    }

    &-sidebar {
        position: relative;
        background: no-repeat url(../../images/demos/ui/generic-sidebar.png); 
        background-size: 270px 500px;
        width: 270px;
        min-width: 270px;
        overflow: hidden;


    }

    &-content {
        width: 100%;
    }

    &-panel {
        width: 368px;
        min-width: 368px;
        border-left: #E1EAEF 1px solid;
    }


    // end layout
}