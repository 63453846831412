html {

    #lds-schedule {
        background: 0 -72px no-repeat url(../../images/schedule/schedule.png); 
        background-size: 1440px 1024px; 
        width: 1440px; 
        height: 854px;
    }

    #lds-schedule-calendar {
        background: no-repeat url(../../images/schedule/schedule-calendar.png); 
        background-size: 1018px 810px; 
        max-width: 1018px; 
        width: 100%;
        height: 810px;
    }

    #lds-schedule-sidenav {
        background: no-repeat url(../../images/schedule/schedule-sidenav.png); 
        background-size: 270px 413px; 
        width: 270px; 
        height: 413px;
    }

    #lds-schedule-panel {
        background: no-repeat url(../../images/schedule/schedule-panel.png); 
        background-size: 368px 951px; 
        width: 368px; 
        height: 951px;
    }

    #lds-schedule-add222 {
        display: none;
        background: no-repeat url(../../images/schedule/schedule-add.png); 
        background-size: 1122px 967px; 
        width: 1122px; 
        height: 1067px;
    }

    #lds-schedule-add {
        position: relative;
        background: repeat-x url(../../images/general/black-line.png);
        background-size: 100px 100px; 
        background-position: 0px -23px;
        // background-color: #FFF7DB;

        .lds-spriteleft,
        .lds-spriteright {
            background: no-repeat url(../../images/schedule/schedule-add.png); 
            background-size: 1122px 967px; 
            width: 600px; 
            height: 1067px;
        }
        .lds-spriteright {
            background-position: top right;
            width: 210px;
            min-width: 210px; // make it stick
        }
    }


} // HTML