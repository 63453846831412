html {


    .lds-list {
        width: 100%;

        &-row {
            height: 44px;
            box-sizing: content-box !important;
            background-color: white;
            border-bottom: #E1EAEF 1px solid;

            // modifiers
            &.is-top {
                border-bottom: #516874 1px solid;
            }

            &.is-alt {
                background-color: #F9FAFB;
            }
        }

        // &-topcell {
        //     padding: 14px 8px;
        //     color: #101828;
        //     white-space: nowrap;

        //     /* Labels/Small - Bold */
        //     font-family: 'Lato';
        //     font-style: normal;
        //     font-weight: 700;
        //     font-size: 14px;
        //     line-height: 16px;

        // }

        &-cell {
            padding: 0 8px 0;
            color: #374D58;
            white-space: nowrap;
            text-align: left;
            vertical-align: middle;

            /* Text sm/Regular */
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;

            &.is-top {
                color: #101828;
                font-weight: 700;
                font-size: 14px;
                line-height: 16px;

                &.is-sortable {
                    cursor: pointer;
                    &:hover {background-color: #efefef;}
                }
            }
        }


    }


    
} // HTML