html {


.lds-sidenav {

    &-top {
        padding: 24px 20px;

        .lds-button {
            width: 100%;
        }
    }


    &-filterlaabel {
        height: 34px;
        padding: 12px 0 4px;

    }

    &-filter {
        position: relative;
        top: 0;
        left: 0;
        height: 24px;
        padding: 0;
    }

    &-filtergroup {
        padding: 12px 18px 0 24px;

        > * {
            margin-bottom: 8px;
        }
    }


    &-tab {
        position: relative;
        top: 0;
        left: 0;
        height: 38px;
        padding: 8px 0 0 54px;
        // border: red 1px solid;

        .lds-icon {
            position: absolute;
            top: 8px;
            left: 24px;
        }

        // modifiers
        &.is-selected {
            background-color: #CEECFF;
            color: #0093E8;
        }
    }

}




} // HTML