html { // HTML

    
    .lds-alert {
        display: flex;
        gap: 20px;
        padding: 16px;
        border-radius: 8px;

        // default
        color: #0171C2;
        background-color: #E9F6FF;
        border: #CEECFF 1px solid;

        
        &-left {
            width: 20px;
            height: 20px;;
        }

        &-center {
            width: 100%;
        }

        &-right {
            width: 20px;
            height: 20px;;
        }

        // modifiers
        &.is-type-error {
            color: #AE0000;
            background-color: #FFEDED;
            border: #FFDCDC 1px solid;
        }
    }

}