

html#lds-html-demos-detailspanel2 {
    // background: repeat-y white url(../../images/demos/detailspanel1/body-bg.png); 
    height: 100% !important;
    overflow: hidden;

    body {
        height: 100% !important;
    }
    
    .lds-detailspanel2 {
        position: relative;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        width: 100%;
        min-height: 100%; // --- NEW
        height: 100%;
        // background-color: blue;

        // members
        &-top {

            height: 72px;
            min-height: 72px;
        }

        &-content {
            display: flex;
            flex-grow: 1;
            height: calc(100% - 72px);
        }

        &-left {
            width: 270px;
            min-width: 270px;
            // background-color: red;
        }

        &-middle {
            flex-grow: 1;
            // height: calc(100% - 62px);
            height: 100%;
            overflow: hidden;
            // width: 270px;
            // min-width: 270px;
            // background-color: yellow;
            // border: teal 10px solid;
        }

        &-right {
            position: relative;
            width: 0;
            min-width: 0;
            // background-color: green;

            &-inner {
                position: absolute;
                top: 0;
                right: 0;
                left: auto;
                width:1px;
                height: 100%;;
                transition: width .6s ease-out;
                transition-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
            }
        }

        &-panel {
            height: 100%;
            width: 368px; 
            border-left: #E1EAEF 1px solid;

            // members
            &-top {
                position: relative;
                top: 0;
                left: 0;
                background: no-repeat white url(../../images/demos/detailspanel1/responsive1-patients-panel.png); 
                background-size: 368px 1560px; 
                // background-color: red;
                height: 62px;
            }
            &-bottom {
                // background-color: yellow;
                // border: blue 10px solid;
                height: calc(100% - 62px);
                overflow-y: auto;
                overflow-x: hidden;

            }
            &-content {
                background: no-repeat white url(../../images/demos/detailspanel1/responsive1-patients-panel.png); 
                background-size: 368px 1560px; 
                background-position: 0 -62px;
                height: 1000px;
                overflow: hidden;
            }
            &-blocker {
            
                display: none;
                cursor: pointer;
                background-color: white;
                opacity: 0;
                width: 368px; 
                height: 1000px;

            }
        }

        // &-panel {
        //     position: relative;
        //     top: 0;
        //     left: 0;


        //     background: no-repeat white url(../../images/demos/detailspanel1/responsive1-patients-panel.png); 
        //     background-size: 368px 1560px; 
        //     width: 368px; 
        //     height: 1560px;
        //     min-height: 100%;
        //     border-left: #E1EAEF 1px solid;


        //     &-blocker {
        //         display: none;
        //         cursor: pointer;
        //         background-color: white;
        //         opacity: 0;
        //         width: 368px; 
        //         height: 1560px;
        //     }
        // }


        &-button {
            position: absolute;
            top: 0;
            left: 0;
            width: 40px;
            height: 40px;
            // border: red 1px solid;
            cursor: pointer;
            z-index: 1;

            // names
            &.is-name-closer {
                top: 16px; 
                left: 312px;
            }
            &.is-name-min {
                top: 16px; 
                left: 272px;
            }
            &.is-name-closer2 {
                display: none;
                top: 16px; 
                left: 4px;
                width: 60px;
                background: white no-repeat 0 0 url(../../images/demos/detailspanel1/responsive1-x.png); 
                background-size: 40px 40px; 
            }
            
            &.is-name-max2 {
                display: none;
                top: 56px; 
                left: 4px;
                width: 60px;
                height: 60px;
                background: white no-repeat 0 0 url(../../images/demos/detailspanel1/responsive1-chevrons.png); 
                background-size: 40px 40px; 
            }

            // &.is-name-max {top: 100px; left: 0;}
        }




        // modifiers
        &.is-mode-min {
            .lds-detailspanel2-right {
                width: 50px;
                max-width: 50px;
                min-width: 50px;
            }
            .lds-detailspanel2-right-inner {
                width: 50px;
            }
            .lds-detailspanel2-button.is-name-max2,
            .lds-detailspanel2-button.is-name-closer2 {
                display: block;
            }

            // .lds-detailspanel2-panel-image {
            //     transition: filter .6s ease-out;
            //     transition-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
            //     filter: blur(2px);
            //     -webkit-filter: blur(2px);
            // }

            .lds-detailspanel2-panel {
                box-shadow: -10px 0px 16px -4px rgba(16, 24, 40, 0.08), -4px 0px 6px -2px rgba(16, 24, 40, 0.03);
            }

            .lds-detailspanel2-panel-blocker {
                display: block;
                opacity: .8;
            }
        }

        &.is-mode-max {
            .lds-detailspanel2-right {
                width: 368px;
                min-width: 368px;
            }
            .lds-detailspanel2-right-inner {
                width: 368px;
            }
        }

        // END COMPOENT
    }


    // --- list --- //

    #lds-detailspanel2-list {
        background: 0 68px white repeat-x url(../../images/general/table-bg.png); 
        background-size: 100px 1276px; 
        width: 100%; 
        height: 816px;

        .lds-spriteleft,
        .lds-spriteright {
            background: repeat-x url(../../images/refills/refills.png); 
            background-size: 1072px 816px; 
            width: 750px; 
            height: 816px;
        }
        .lds-spriteright {
            background-position: top right;
            width: 200px;
            min-width: 200px; // make it stick
        }
    }

    // END HTML
}






// --- responsive --- //

@media (max-width: 1500px) {

    html#lds-html-demos-detailspanel2 {

        body .lds-detailspanel2.is-mode-max .lds-detailspanel2-right {
            width: 1px;
            max-width: 1px;
            min-width: 1px;
        }

        // body .lds-detailspanel2.is-mode-min .lds-detailspanel2-panel {
        //     box-shadow: -10px 0px 16px -4px rgba(16, 24, 40, 0.08), -4px 0px 6px -2px rgba(16, 24, 40, 0.03);
        // }

        // body .lds-detailspanel2.is-mode-max .lds-detailspanel2-panel {
        body .lds-detailspanel2 .lds-detailspanel2-panel {
            box-shadow: -10px 0px 16px -4px rgba(16, 24, 40, 0.08), -4px 0px 6px -2px rgba(16, 24, 40, 0.03);
        }




    //     body .lds-layout2-right {
    //         // border: red 1px solid !important;
    //         // left: -368px;
    //         width: 1px;
    //         // min-width: 
    //         // position: relative;
    //     }
    
    //     body .lds-layout2.is-panelmode-max .lds-layout2-right {
    //         width: 1px;
    //         left: -368px;
    //         min-width: 1px;
    //         max-width: 1px;
    //     }
    
    //     // html .lds-layout2.is-panelmode-min .lds-layout2-right {
    //     //     width: 50px;
    //     // }
    
    //     body .lds-panel7 {
    //         box-shadow: -10px 0px 16px -4px rgba(16, 24, 40, 0.08), -4px 0px 6px -2px rgba(16, 24, 40, 0.03);
    //     }
    }

}


