
/*
html .panel {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background-color: red;
    border: green 10px solid;

    &-top {
        height: 200px;
        background-color: blue;
    }
    &-bottom {
        // height: calc(100% - 200px);
        background-color: blue;
    }
}
*/

html#lds-html-demos-formerror1 {
    height: 100% !important;
    background: repeat-y white url(../../images/demos/bottommargin1/body-bg.png); 
    overflow: hidden;
    // padding-top: 72px;

    // body
    > body {
        height: 100% !important;
    }



    // page
    .lds-msgdrill1 {
        position: relative;
        top: 0;
        left: 0;
        display: flex;
        // flex-direction: column;
        width: 100%;
        min-height: 100%; // --- NEW
        // height: 100%;
        height: calc(100% - 72px);
        // border: red 10px solid;
    

        &-left {
            width: 270px;
            min-width: 270px;
            overflow: hidden;
            // background-color: red;

            // msgdrill1-sidebar

            // background: no-repeat url(../../images/demos/msgdrill1/msgdrill1-sidebar.png); 
            background: no-repeat url(../../images/demos/ui/generic-sidebar.png); 
            background-size: 270px 500px; 
        }

        &-middle {
            flex-grow: 1;
            // height: calc(100% - 62px);
            height: 100%;
            overflow-x: hidden;
            overflow-y: auto;
            // width: 270px;
            // min-width: 270px;
            // background-color: yellow;
            // border: teal 10px solid;
            padding: 0 24px 100px;
        }

        &-content {
            position: relative;
            top: 0;
            left: 0;
            // background: no-repeat url(../../images/demos/msgdrill1/msgdrill1-content.png); 
            // background-size: 1000px 848px; 
            background: no-repeat url(../../images/demos/ui/generic-list.png); 
            background-size: 1024px 816px; 
            width: 1024px;
            height: 816px;

        }

        &-right {
            position: relative;
            width: 350px;
            min-width: 350px;
            // background-color: gray;
            // border-left: gray 1px solid;
            overflow-x: hidden;
            overflow-y: auto;
            // background-color: green;
        }

        &-panel {
            background: no-repeat url(../../images/demos/msgdrill1/msgdrill1-panel.png); 
            background-size: 368px 1628px; 
            width: 368px;
            height: 1628px;
        }

        &-modal {
            background: no-repeat url(../../images/messages/messages-new-modal3.png); 
            // background: no-repeat url(../../images/);
            background-size: 768px 1065px; // 768px 1092px; 
            width: 768px; 
            height: 1065px;
        }
        
        // end page content
    }


    // modal
    #lds-msgdrill1-modal {
        background: no-repeat url(../../images/demos/formerror1/formerror1-form.png); 
        background-size: 768px 918px; // 768px 1092px; 
        width: 768px; 
        height: 918px;

        // inner
        &-group1 {
            position: absolute !important;
            top: 0;
            left: 0;
        }
        &-group2 {
            position: absolute !important;
            top: 0;
            left: 0;
        }

        
        // modifiers
        &.is-error {
            background: no-repeat url(../../images/demos/formerror1/formerror1-form-with-errors.png); 
            background-size: 768px 1028px; // 768px 1092px; 
            width: 768px; 
            height: 1035px;

            // inner
            #lds-msgdrill1-modal-group1 {
                top: 70px !important;
            }
            #lds-msgdrill1-modal-group2 {
                top: 100px !important;
            }
        }
    }

    // end html
}




// #lds-messages-new-modal2 {
//     background: no-repeat url(../../images/messages/messages-new-modal3.png); 
//     background-size: 768px 1065px; // 768px 1092px; 
//     width: 768px; 
//     height: 1065px;
// }



// --- responsive --- //

@media (max-width: 1500px) {

    html#lds-html-demos-detailspanel2 {

        // body .lds-detailspanel2.is-mode-max .lds-detailspanel2-right {
        //     width: 1px;
        //     max-width: 1px;
        //     min-width: 1px;
        // }

        // // body .lds-detailspanel2.is-mode-min .lds-detailspanel2-panel {
        // //     box-shadow: -10px 0px 16px -4px rgba(16, 24, 40, 0.08), -4px 0px 6px -2px rgba(16, 24, 40, 0.03);
        // // }

        // // body .lds-detailspanel2.is-mode-max .lds-detailspanel2-panel {
        // body .lds-detailspanel2 .lds-detailspanel2-panel {
        //     box-shadow: -10px 0px 16px -4px rgba(16, 24, 40, 0.08), -4px 0px 6px -2px rgba(16, 24, 40, 0.03);
        // }


    }

}


