html {

    #lds-app-messages-chat {
        height: 100%;
    }


    // --- chat --- //

    .lds-chat {
        display: flex;
        flex-direction: column;
        height: 100%;

        // modifiers
        &.is-keyboard {
            
            .lds-chat-bottom {
                height: 363px; // 291 + 72

            }
            .lds-sprite-keyboard {
                display: block !important;
            }
        }

        // &.is-sent {
        //     .lds-chat-convo {
        //         height: 340px;
        //     }
        // }

        // members
        &-top {
            display: flex;
            align-items: flex-end;
            flex-grow: 1;
            overflow-y: auto;
            overflow-x: hidden;
        }

        &-bottom {

            transition: all .4s ease-out;
            transition-timing-function: cubic-bezier(0.16, 1, 0.3, 1);

            height: 92px;

            .lds-input-field {
                font-size: 16px;
            }

            .lds-sprite-keyboard {
                display: none;
            }
        }

        &-convo {
            position: relative;
            top: 0;
            left: 0;
            background: no-repeat top left url(../../images/app/messages/chat-convo2.png);
            background-size: 375px 600px; // was 351px
            width: 375px;
            height: 187px;
            transition: height .5s ease-out;
            transition-timing-function: cubic-bezier(0.16, 1, 0.3, 1);

            // chats
            &.is-count-1 {
                height: 340px;
            }
            &.is-count-2 {
                height: 396px;
            }
            &.is-count-3 {
                height: 456px;
            }

            &.is-count-4,
            &.is-count-5 {
                height: 526px;
            }
        }
    }

    // sprite
    #lds-messages-chat-input {
        background: url(../../images/app/messages/chat-input.png);
        background-size: 375px 72px;
        width: 375px;
        height: 72px;
    }

    #lds-messages-chat-new {
        background: url(../../images/app/messages/chat-new.png);
        background-size: 375px 704px;
        width: 375px;
        height: 704px;
    }

} // html