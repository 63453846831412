

html { // HTML

// NUMBERS AND LETTERS
// ABCDEFGHIJKLMNOPQRSTUVWXYZ 0123456789 !@#$%^&*()_+{}|:"?-=[];,./`

// --- general -- //

.is-upper {text-transform: uppercase !important;}
.is-lower {text-transform: lowercase !important;}

.is-leading {letter-spacing: .5px !important;}
.is-leading2 {letter-spacing: 1px !important;}



// --- text -- //

.is-text-xs,
.is-text-sm,
.is-text-md,
.is-text-lg,
.is-text-xl {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;

    &-l, &-m, &-s, &-b, &-x {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400; // default weight
    }

    // weight
    &-l {font-weight: 300;}
    // &-m {font-weight: 500;} // 
    &-s {font-weight: 600;}
    &-b {font-weight: 700;}
    &-x {font-weight: 900;}
}


.is-text-xxs {
    font-size: 11px;
    line-height: 16px;

    &-l, &-m, &-s, &-b, &-x {
        font-size: 11px;
        line-height: 16px;
        // weight is 400 (above)
    }

    // weight
    &-l {font-weight: 300;}
    // &-m {font-weight: 500;}
    &-s {font-weight: 600;}
    &-b {font-weight: 700;}
    &-x {font-weight: 900;}
}

.is-text-xs {
    font-size: 12px;
    line-height: 18px;

    &-l, &-m, &-s, &-b, &-x {
        font-size: 12px;
        line-height: 18px;
        // weight is 400 (above)
    }

    // weight
    &-l {font-weight: 300;}
    &-m {font-weight: 500;}
    &-s {font-weight: 600;}
    &-b {font-weight: 700;}
    &-x {font-weight: 900;}
}

.is-text-sm {
    font-size: 14px;
    line-height: 20px;

    &-l, &-m, &-s, &-b, &-x {
        font-size: 14px;
        line-height: 20px;
        // weight is 400 (above)
    }

    // weight
    &-l {font-weight: 300;}
    // &-m {font-weight: 500;}
    &-s {font-weight: 600;}
    &-b {font-weight: 700;}
    &-x {font-weight: 900;}
}

.is-text-md {
    font-size: 16px;
    line-height: 24px;

    &-l, &-m, &-s, &-b, &-x {
        font-size: 16px;
        line-height: 24px;
        // weight is 400 (above)
    }

    // weight
    &-l {font-weight: 300;}
    // &-m {font-weight: 500;}
    &-s {font-weight: 600;}
    &-b {font-weight: 700;}
    &-x {font-weight: 900;}
}

.is-text-lg {
    font-size: 18px;
    line-height: 28px;

    &-l, &-m, &-s, &-b, &-x {
        font-size: 18px;
        line-height: 28px;
        // weight is 400 (above)
    }

    // weight
    &-l {font-weight: 300;}
    // &-m {font-weight: 500;}
    &-s {font-weight: 600;}
    &-b {font-weight: 700;}
    &-x {font-weight: 900;}
}

.is-text-xl {
    font-size: 20px;
    line-height: 30px;

    &-l, &-m, &-s, &-b, &-x {
        font-size: 20px;
        line-height: 30px;
        // weight is 400 (above)
    }

    // weight
    &-l {font-weight: 300;}
    // &-m {font-weight: 500;}
    &-s {font-weight: 600;}
    &-b {font-weight: 700;}
    &-x {font-weight: 900;}
}


// --- text -- //

.is-label-xs,
.is-label-sm,
.is-label-md,
.is-label-lg,
.is-label-xl {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;

    &-l, &-m, &-s, &-b, &-x {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
    }

    // weight
    &-l {font-weight: 300;}
    // &-m {font-weight: 500;}
    &-s {font-weight: 600;}
    &-b {font-weight: 700;}
    &-x {font-weight: 900;}
}

.is-label-xxs {
    font-size: 12px;
    line-height: 14px;

    &-l, &-m, &-s, &-b, &-x {
        font-size: 12px;
        line-height: 18px;
    }

    // weight
    &-l {font-weight: 300;}
    &-m {font-weight: 500;}
    &-s {font-weight: 600;}
    &-b {font-weight: 700;}
    &-x {font-weight: 900;}
}

.is-label-xs {
    font-size: 13px;
    line-height: 15px;

    &-l, &-m, &-s, &-b, &-x {
        font-size: 13px;
        line-height: 15px;
    }

    // weight
    &-l {font-weight: 300;}
    &-m {font-weight: 500;}
    &-s {font-weight: 600;}
    &-b {font-weight: 700;}
    &-x {font-weight: 900;}
}

.is-label-sm {
    font-size: 14px;
    line-height: 16px;

    &-l, &-m, &-s, &-b, &-x {
        font-size: 16px;
        line-height: 18px;
    }

    // weight
    &-l {font-weight: 300;}
    &-m {font-weight: 500;}
    &-s {font-weight: 600;}
    &-b {font-weight: 700;}
    &-x {font-weight: 900;}
}

.is-label-md {
    font-size: 16px;
    line-height: 18px;

    &-l, &-m, &-s, &-b, &-x {
        font-size: 16px;
        line-height: 18px;
    }

    // weight
    &-l {font-weight: 300;}
    &-m {font-weight: 500;}
    &-s {font-weight: 600;}
    &-b {font-weight: 700;}
    &-x {font-weight: 900;}
}

.is-label-lg {
    font-size: 18px;
    line-height: 20px;

    &-l, &-m, &-s, &-b, &-x {
        font-size: 18px;
        line-height: 20px;
    }

    // weight
    &-l {font-weight: 300;}
    &-m {font-weight: 500;}
    &-s {font-weight: 600;}
    &-b {font-weight: 700;}
    &-x {font-weight: 900;}
}

.is-label-xl {
    font-size: 20px;
    line-height: 22px;

    &-l, &-m, &-s, &-b, &-x {
        font-size: 20px;
        line-height: 22px;
    }

    // weight
    &-l {font-weight: 300;}
    &-m {font-weight: 500;}
    &-s {font-weight: 600;}
    &-b {font-weight: 700;}
    &-x {font-weight: 900;}
}

.is-label-xxl {
    font-size: 24px;
    line-height: 26px;

    &-l, &-m, &-s, &-b, &-x {
        font-size: 24px;
        line-height: 26px;
    }

    // weight
    &-l {font-weight: 300;}
    &-m {font-weight: 500;}
    &-s {font-weight: 600;}
    &-b {font-weight: 700;}
    &-x {font-weight: 900;}
}



} // HTML