
html .lds-fauxlist {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    // height: 800px;
    // flex-grow: 1;
    // height: calc(100% - 400px);
    // height: 728px;

    &-top {
        height: 112px;
    }
    &-bottom {
        // height: calc(100% - 112px);
        // background-color: blue;
        overflow-y: auto;
        overflow-x: hidden;
        // border: green 10px solid;
    }

    .lds-fauxsplit.is-type-header {
        height: 112px;
        background-image: url(../../images/demos/ui/fauxlist-header-bg.png);
        background-size: 400px 112px;
        > * {
            background-image: url(../../images/demos/ui/fauxlist-header.png);
            background-size: 1024px 112px;  
        }
    }
    
    .lds-fauxsplit.is-type-rows {
        height: 1408px;
        background-image: url(../../images/demos/ui/fauxlist-rows-bg.png);
        background-size: 100px 1232px; 
        > * {
            background-image: url(../../images/demos/ui/fauxlist-rows.png);
            background-repeat: repeat-y;
            background-size: 1024px 704px;
        }
        .lds-fauxsplit-left {
            width: 550px; // overrides
        }
    }
    

}

