html {


    .lds-text {
        display: inline;
    }

    .lds-text {
        display: inline;
    }


    .lds-badge {
        display: inline-block;
        padding: 2px 8px;
        border-radius: 100px;
        background-color: #eee;
        color: #333;
        border: white 1px solid;

        // color
        &.is-type-teal {
            background-color: #CFF9FE; 
            color: #0E7090;
        }
        
    }

    .lds-chain {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 4px;
    
        // modifiers
        &.is-valign-top {align-items: flex-start}
        &.is-valign-middle {align-items: center;}
        &.is-valign-bottom {align-items: flex-end;}
        &.is-valign-baseline { align-items: baseline;}
    }


} // HTML