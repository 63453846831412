html {

    // hide topnav
    body.lds-body-webapp {
        padding-top: 0;
        background-color: #374D58;

        .lds-topnav {
            display: none;
        }
    }

    // // web app
    // .lds-webapp {


    // }



    #lds-webapp-topnav {
        background: repeat-x url(../../images/webapp/webapp-topnav-bg.png); 
        background-size: 10px 88px; 
        width: 100%; 
        height: 88px;

        .lds-spriteleft,
        .lds-spriteright {
            background: 0 0 url(../../images/webapp/webapp-topnav.png);
            background-size: 1440px 88px; 
            width: 820px; 
            height: 88px;
            cursor: pointer;
        }
        .lds-spriteright {
            background-position: top right;
            width: 160px;
            min-width: 160px; // make it stick
        }
    }

    #lds-webapp-content {
        margin: 0 auto;
        background: no-repeat url(../../images/webapp/webapp-content.png); 
        background-size: 900px 786px; 
        width: 900px; 
        height: 786px;
        border-radius: 8px;;
        box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08);

        // outer
        &-outer {
            padding: 80px;
            background-color: #F5F9FD;
        }
    }

    #lds-webapp-footer {
        margin: 0 auto;
        background: no-repeat url(../../images/webapp/webapp-footer.png); 
        background-size: 901px 94px; 
        width: 901px; 
        height: 94px;

        // outer
        &-outer {
            width: 100%;
            background-color: #374D58;
            padding: 44px;
            border-top: #B9C0D4 8px solid;
        }
    }


} // html