html {

    .lds-demos-colorpicker {
        // background: url(../../images/demos/ui/demos-colorpicker.png);
        background-color: white !important;
        background-size: cover; // 856px 236px
        // width: 327px;
        // height: 90px;
        width: 442px; // 500px;
        height: 120px;
        margin: 10px;

        // members
        &-list {
            position: relative;
            top: -4px;
            left: -4px;
            width: 102%; // 
            // width: 340px;
        }

        &-item {
            position: relative !important;
            width: 32px;
            height: 32px;
            float: left;
            border: white 2px solid;
            border-radius: 5px;
            transition: border-color .3s ease-out;

            // border: blue 2px solid;

            > div {
                position: relative;
                top: 2px;
                left: 2px;
                background-color: gray;
                width: 24px;
                height: 24px;
                border-radius: 3px;

            }

            &.is-selected {
                border-color: #0093E8;
            }

                
            &:hover {
                border-color: gray;
            }

        }

        &-color {

        }

        // modifiers
        &.is-changed {
            // background-image: url(../../images/demos/ui/demos-colorpicker-selected.png);
        }
    }


} // html