html {


    // --- TOGGLE --- //

    .lds-toggle {
        display: flex;
        color: #374D58;
        font-size: 14px;
        font-family: Lato;
        font-weight: 500;
        line-height: 20px;
        cursor: pointer;

        // modifiers
        &.is-sprite {
            .lds-toggle-control {
                outline: white 2px solid;
            }
        }

        &.is-flipped {
            flex-direction: row-reverse;
        }

        &.is-on {
            .lds-toggle-control {
                background-position: 0 0;;
                background-color: #0093E8; // --- NEW
            }

            .lds-toggle-dot {
                left: 18px;
                // transition: all .4s ease-out;
                // transition-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
            }
        }

        &.is-size-lg {
            &.is-on .lds-toggle-dot {
                left: 22px;
            }
            .lds-toggle-control {
                width: 44px;
                height: 24px;
            }
            .lds-toggle-dot {
                width: 20px;
                height: 20px;
            }
        }
        

        // // members
        // &-control222 {
        //     width: 36px;
        //     height: 20px;
        //     background: no-repeat url(../../images/general/toggle.png);
        //     background-size: 44px 118px;
        //     background-position: 0 -30px;
        //     border-radius: 100px;
        // }
        
        // members
        &-control {
            position: relative;
            top: 0;
            left: 0;
            width: 36px;
            height: 20px;
            border-radius: 100px;
            background-color: #E1EAEF;
            transition: background-color .1s linear;
        }

        &-dot {
            position: absolute;
            top: 2px;
            left: 2px;
            width: 16px;
            height: 16px;
            background-color: white;
            border-radius: 100px;
            transition: left .1s linear;
            filter: drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.06)) drop-shadow(0px 1px 3px rgba(16, 24, 40, 0.10));

        }

        &-label {
            padding-left: 8px;
        }
    }

    // --- CHECKBOX --- //

    .lds-checkbox {
        position: relative;
        top: 0;
        left: 0;
        display: inline-block;
        cursor: pointer;
        padding: 0px 0 0 28px;   
        min-height: 20px;
        
        .lds-icon {
            position: absolute;
            top: 0;
            left: 0;
            // border-radius: ;
        }
        
        // modifier
        &.is-notlabeled {padding: 0 0 0 20px; }

        &.is-size-sm {
            min-height: 16px;
            &.is-notlabeled {padding: 0 0 0 16px; }
        }
        &.is-size-lg {
            min-height: 24px;
            &.is-notlabeled {padding: 0 0 0 24px; }
        }

        &.is-sprite {
            .lds-icon {
                outline: white 2px solid;
                border-radius: 4px;
            }
            &.is-type-radio .lds-icon {
                border-radius: 100px;
            }
        }

    }


    // --- INPUT --- //

    .lds-input {
        position: relative;
        top: 0;
        left: 0;
        display: inline-block;
        text-align: center;
        width: 100%;
        height: 58px;
        color: #374D58;
        background: white;
        border: 1px solid #BCC8CE;
        border-radius: 6px;
        cursor: text;

        // members
        &-contianer {
            width: 220px;
            position: relative;
            top: 0;
            left: 0;
        }

        &-label {
            display: none;
            position: absolute;
            top: 0;
            left: 0;
            padding: 7px 14px 0;
            width: 100%;
            text-overflow: ellipsis;
            overflow: hidden;
            text-align: left;
            white-space: nowrap;
            color: #72848e !important; // #526874; // NEW

            // --- KW
            cursor: default;
            display: block;
            opacity: 0;
            // padding-top: 9px;
            transition: all .2s ease-out;
        }

        &-hint {
            display: flex;
            margin-top: 6px;
            align-items: center;
            color: #748995;
            gap: 4px;

            &.is-type-error {
                color: #D00000;
            }

            &.is-type-success {
                color: #2A9B02;
            }
        }

        &-field {
            display: block;
            width: 100%;
            height: 100%;
            padding: 12px 14px 12px 14px;
            background-color: white;
            border-width: 0;
            text-overflow: ellipsis;
            overflow: hidden;
            text-align: left;
            border-radius: 6px;

            &::placeholder {
                color: #748995;
            }

            &:focus {
                background-color: white;
            }

            // --- KW
            background-color: transparent;
        }

        &-icon {
            position: absolute;
            left: 14px;
            top: 19px;
        }

        &-icon-right {
            position: absolute;
            left: auto;
            right: 14px;
            top: 19px;
        }

        // filled
        &.is-filled {
            .lds-input-label {
                display: block;
                
                // --- KW
                opacity: 1;
                // padding-top: 7px;
            }
    
            .lds-input-field {
                padding: 27px 14px 12px 14px;
                // TODO
            }
        }

        // iconic
        &.is-iconic {
            .lds-input-label,
            .lds-input-field {
                padding-left: 47px !important;
            }
        }
        &.is-iconic-right {
            .lds-input-label,
            .lds-input-field {
                padding-right: 47px !important;
            }
        }

        // disabled
        &.is-disabled .lds-input-field {
            background-color: #F5F9FD;
        }


        // select
        &.is-type-select > * {
            cursor: pointer;
        }

        // textarea
        &.is-type-textarea {
            height: 124px;
            textarea {
                resize: none;
            }
        }


        // error
        &.is-error {
            border-color: #D00000;
        }

        // sprite 
        &.is-sprite {
            height: 50px; // originally 58px
            border-width: 0;

            .lds-input-label {
                padding-left: 4px !important;
                padding-right: 4px !important;
            }

            .lds-input-field {
                // padding-bottom: 4px !important;
                // padding-left: 4px !important;
                // padding-right: 4px !important;
                padding: 4px !important;
                outline: none !important;
            }

            &.is-filled .lds-input-field {
               padding-top: 27px !important;
            }
        }

        // modifiers

    }


    // standard

    .lds-input.is-standard {
        height: 44px;

        
        .lds-input-field {
            padding: 10px 14px;
        }
        
        .lds-input-label {
            display: none !important;
        }

        // sprite
        &.is-sprite {
            height: 34px;
            border-width: 0;

            .lds-input-field {
                padding: 4px !important;
                outline: none;
            }
        }
    }


    // --- SELECT --- //

    .lds-select {
        // display: inline-block;
        white-space: nowrap;
        cursor: pointer;
        display: flex;
        align-items: center;

        // text 
        &-text {
            display: flex;
            align-items: center;
            cursor: pointer;
        }
    }

        
    ul.lds-selectlist {
        min-width: 200px;
        padding: 8px 0;
        border-radius: 4px;

    }

    li.lds-selectitem {
        padding: 2px 4px;
        font-size: 14px;
        line-height: 13px;
        height: 32px;

        /* Text sm/Regular */
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;

        &.is-selected {
            color: #0093E8;
        }
    }



    // --- SELECT --- //




} // HTML