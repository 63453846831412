

html .lds-fauxsplit {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 112px; // overrides
    display: flex;
    justify-content: space-between;
    background: repeat-x white none;
    background-image: url(../../images/demos/ui/fauxlist-header-bg.png); // overrides
    background-size: 400px 112px; // overrides

    > * {
        background: white none; 
        background-image: url(../../images/demos/ui/fauxlist-header.png); // overrides
        background-size: 1024px 112px; // overrides
    }

    &-left {
        background-position: top left;
        width: 400px; // overrides
    }
    &-right {
        background-position: top right;
        width: 200px; // override
    }
}
