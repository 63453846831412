html {

    #lds-marketing-sidenav {
        background: no-repeat url(../../images/marketing/marketing-sidenav.png); 
        background-size: 270px 300px; 
        width: 270px; 
        height: 300px;
    }

    #lds-marketing-library {
        background: no-repeat url(../../images/marketing/marketing-library.png); 
        background-size: 1000px 1100px; 
        width: 1000px; 
        height: 1100px;
    }


} // html