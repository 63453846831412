

html .lds-genphone {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    // display: none;
    display: none;
    align-items: center;
    justify-content: center;
    // background-color: #555555;
    background-color: rgba(255, 255, 255, .5);

    // modifiers
    &.is-open {
        display: flex;
    }
    
    // members
    // &-wrapper222 {
    //     // width: 375px;
    //     // height: 600px;
    //     // border: red 1px solid;
    //     // overflow-x: hidden;
    //     // overflow-y: scroll;
    //     position: relative;
    //     top: 0;
    //     left: 0;
    //     width: 512px;
    //     height: 870px;
    //     // background: url(../../images/general/iphone.png);
    //     // background: none no-repeat;
    //     background-image: url(../../images/demo/genphone-home.png);
    //     background-repeat: no-repeat;
    //     background-size: 512px 933px;
    //     background-position: 0 -28px;
    // }

    &-wrapper {
        position: relative;
        top: 0;
        left: 0;
        width: 402px;
        height: 838px;
        background-image: none;
        background-repeat: no-repeat;
        background-size: 512px 933px;
        background-position: -49px -39px;
        border-radius: 60px;
        box-shadow: 0px 4px 54px 0px rgba(0, 0, 0, 0.25), 0px 5px 7px 4px rgba(56, 64, 71, 0.35);

    }

    &-content {
        position: absolute;
        top: 162px;
        left: 17px;
        width: 370px;
        height: 571px;
        overflow-x: hidden;
        overflow-y: scroll;
    }

    // &-content {
    //     position: absolute;
    //     top: 173px;
    //     left: 66px;
    //     width: 370px;
    //     height: 571px;
    //     overflow-x: hidden;
    //     overflow-y: scroll;
    // }


    // modifiers
    &.is-type-home {
        .lds-genphone-wrapper {
            background-image: url(../../images/demo/genphone-home.png);
        }
    }

    &.is-type-meds {
        .lds-genphone-wrapper {
            background-image: url(../../images/demo/genphone-meds.png);
        }
        .lds-genphone-content {
            top: 123px;
            height: 609px;
        }
    }

    // true play





    &.is-opensheet {

        .lds-genphone-sheet {
            opacity: 1;
            width: 371px;
        }

        .lds-genphone-sheet-inner {
            bottom: 0;
        }

    }

    &-sheet {
        // display: none;
        position: absolute;
        z-index: 1000;
        top: 16px;
        left: 16px;
        width: 0;
        height: 806px;
        border-radius: 42px;
        overflow: hidden;
        background-color: rgba(0, 0, 0, 0.5);
        opacity: 0;
        transition: opacity .2s ease-in-out;

        // background-color: white;

        // title
        &-title {
            position: absolute;
            top: 12px;
            left: 0;
            width: 100%;
            color: #101828;
            text-align: center;
            font-family: Lato;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
        }

        // x
        &-x {
            position: absolute;
            top: 12px;
            left: 336px;
            width: 24px;
            height: 24px;
            // border: red 1px solid;
            cursor: pointer;
            background: no-repeat url(../../images/demo/genphone-x.png);
            background-size: 24px 24px;
        }

        // inner
        &-inner {
            position: absolute;
            top: auto;
            bottom: -200px;
            left: 0;
            height: 100px;
            width: 100%;
            border-radius: 12px;
            background-color: white;
            box-shadow: 0px -4px 8px -2px rgba(16, 24, 40, 0.50);
            overflow: hidden;
            transition: bottom .5s cubic-bezier(0.4, 0, 0.2, 1);
        }

        // content
        &-content {
            position: absolute;
            top: 50px;
            left: 0;
            width: 100%;
            // border: red 1px solid;
        }
    }





    &.is-openmodal .lds-genphone-modal {
        opacity: 1;
        width: 402px;
    }

    // --- modal
    &-modal {
        position: absolute;
        z-index: 1000;
        // position: relative;
        opacity: 0;
        transition: opacity .2s ease-in-out; // cubic-bezier(0.4, 0, 0.2, 1);
        // transition: top .5s cubic-bezier(0.4, 0, 0.2, 1);
        top: 0;
        top: 0;
        left: 0;
        width: 1px;
        height: 838px;
        // background-image: none;
        overflow: hidden;
        background-image: url(../../images/demo/genphone-modal.png);
        background-repeat: no-repeat;
        background-size: 512px 933px;
        background-position: -49px -39px;
        border-radius: 60px;

        // title
        &-title {
            position: absolute;
            top: 75px;
            left: 0;
            width: 100%;
            color: #101828;
            text-align: center;
            font-family: Lato;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
        }

        // x
        &-x {
            position: absolute;
            top: 70px;
            left: 340px;
            width: 40px;
            height: 30px;
            // border: red 1px solid;
            cursor: pointer;
        }


        // content
        &-content {
            position: absolute;
            top: 110px;
            left: 17px;
            width: 370px;
            height: 712px;
            border-radius: 0 0 44px 44px;
            overflow-x: hidden;
            overflow-y: auto;
            // background-color: red;
        }

        // bottom
        &-bottom {
            position: absolute;
            top: auto;
            bottom: 16px;
            left: 17px;
            width: 370px;
            height: 0; // see component
            border-radius: 0 0 44px 44px;
            overflow: hidden;
            // background-color: red;
        }



    }


}



    // &-content2 {
    //     position: absolute;
    //     top: 28px;
    //     left: 63px;
    //     width: 375px;
    //     height: 812px;
    //     border-radius: 42px;
    //     overflow-x: hidden;
    //     overflow-y: scroll;
    //     // border: red 1px solid;
    // }