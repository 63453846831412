
html#lds-html-demos-bottommargin1 {

    height: 100% !important;
    background: repeat-y white url(../../images/demos/bottommargin1/body-bg.png); 
    overflow: hidden;

    > body {
        height: 100% !important;
    }

    #lds-responsive-panel {
        // background-image: url(../../images/demos/ui/onboard-topnav.png);
        background: no-repeat url(../../images/demos/bottommargin1/responsive1-patients-panel.png); 
        background-size: 368px 1560px; 
        width: 368px; 
        height: 1560px;
    }

    body#lds-body-demos-responsive1 {
        // padding-top: 72px !important;
        
    }

    #lds-tasks-sidenav {
        background: repeat-x url(../../images/tasks/tasks-sidenav.png); 
        background-size: 270px 700px; 
        height: 800px;
    }

    #lds-tasks-panel-top {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        background-color: blue;
        height: 40px;;
    }

    .lds-list2 {
        height: 100%;
        width: 100%;
        background-color: white;

        &-top {
            position: relative;
            top: 0;
            left: 0;
            height: 146px;
            // background-color: blue;
        }

        &-content-outer {
            width: 100%;
            // height: calc(100% - 120px);
            // height: calc(100% - 246px);
            height: calc(100% - 146px);
            overflow-y: auto;
            overflow-x: hidden;
            // background-color: yellow;
        }

        &-content {

            // background: no-repeat 0 -64px url(../../images/demos/bottommargin1/responsive1-patients-panel.png); 
            // background-size: 368px 1560px; 
            // height: 1560px;

        }

        &-bottom {
            position: relative;
            top: 0;
            left: 0;
            height: 0;
        }

    }


    // --- panel --- //

    .lds-panel2 {
        height: 100%;
        width: 368px;
        background-color: white;

        &-top {
            position: relative;
            top: 0;
            left: 0;
            height: 62px;
            background: no-repeat 0 0 url(../../images/demos/bottommargin1/responsive1-patients-panel.png); 
            background-size: 368px 1560px; 
            // background-image: url(../../images/demos/ui/onboard-topnav.png);
            // background-size: 270px 700px; 
            // background-color: yellow;
        }
        &-content-outer {
            width: 100%;
            height: calc(100% - 62px);
            overflow-y: auto;
            overflow-x: hidden;
        }
        &-content {
            background: no-repeat 0 -64px url(../../images/demos/bottommargin1/responsive1-patients-panel.png); 
            background-size: 368px 1560px; 
            height: 1510px; // 1560px;

            // width: 368px; 
            // height: 1252px;

            // height: calc(100% - 62px);
            // border: green 10px solid;
        }

        &-overlay {
            display: none;
            background-color: rgba(255,255,255,.9);
        }
        
        &-x {
            background: no-repeat 0 0 url(../../images/demos/bottommargin1/responsive1-x.png); 
            background-size: 40px 40px; 
        }
        
        &-chevrons {
            background: no-repeat 0 0 url(../../images/demos/bottommargin1/responsive1-chevrons.png); 
            background-size: 40px 40px; 
        }

    }

    // --- layout --- //
    
    .lds-layout2 {
        position: relative;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        width: 100%;
        min-height: 100%; // --- NEW
        height: 100%;
    
    
        &-top {
            height: 72px;
            min-height: 72px;
        }
    
        &-content {
            display: flex;
            flex-grow: 1;
            height: calc(100% - 72px);
        }
    
        &-left {
            width: 270px;
            min-width: 270px;
            overflow-y: auto;
            overflow-x: hidden;
        }
        
        &-main {
            flex-grow: 1;
            overflow-y: auto;
            overflow-x: hidden;
            padding: 0 24px;
        }

        &-right {
            // display: none;
            position: relative;
            top: 0;
            left: 0;
            width: 0;
            min-width: 0;
            border-left: #F0F4F7 1px solid;


            // transition: width .6s ease-out;
            // transition-timing-function: cubic-bezier(0.16, 1, 0.3, 1);


            // modifiers
            // &.is-overlay {
            //     left: -368px;
            //     width: 1px;
            //     position: relative;
            // }

        }

        // modifiers

        // &.is-showpanel {
        //     .lds-layout2-right {
        //         width: 368px;
        //         // display: block;
        //     }
        // }

        // &.is-minpanel {
        //     .lds-layout2-right {
        //         width: 50px;
        //         // display: block;
        //     }
        // }
        
        &.is-panelmode-min .lds-layout2-right  {
            width: 50px;
        }
        &.is-panelmode-min .lds-panel2 {
            box-shadow: -10px 0px 16px -4px rgba(16, 24, 40, 0.08), -4px 0px 6px -2px rgba(16, 24, 40, 0.03);
        }

        &.is-panelmode-min .lds-layout2-right {
            // border-left: #ccc 1px solid !important;
        }

        &.is-panelmode-min .lds-panel2-overlay {
            display: block !important;
        }


        

        &.is-panelmode-max .lds-layout2-right  {
            width: 368px;
            min-width: 368px;
        }
        

    }
    
    

}

.lds-accessibility-button {
    background: 0 0 url(../../images/demos/bottommargin1/access-button.png); 
    background-size: 44px 44px;
    // position: fixed;
    position: fixed;


}

#lds-tasks2 {
    background: 0 101px white repeat-x url(../../images/general/table-bg.png); 
    background-size: 100px 1276px; 
    width: 100%; 
    min-width: 830px;
    height: 146px;

    .lds-spriteleft,
    .lds-spriteright {
        background: repeat-x url(../../images/tasks/tasks-list2.png); 
        background-size: 754px 761px; 
        width: 580px; 
        height: 146px;
    }
    .lds-spriteright {
        background-position: top right;
        width: 170px;
        min-width: 170px; // make it stick
    }
}

#lds-tasks3 {
    background: 0 -44px white repeat-x url(../../images/general/table-bg.png); 
    background-size: 100px 1276px; 
    width: 100%; 
    min-width: 830px;
    // height: 761px;
    height: 1012px;
    overflow: hidden;

    .lds-spriteleft,
    .lds-spriteright {
        background: repeat-x url(../../images/demos/bottommargin1/tasks-list3.png); 
        // background-size: 754px 761px; 
        background-size: 754px 1201px; 
        width: 580px; 
        height: 1000px;
        background-position: 0 -145px;
    }
    .lds-spriteright {
        // background-position: -145px right;
        background-position-x: right;
        background-position-y: -145px;
        width: 170px;
        min-width: 170px; // make it stick
    }
}





// --- responsive --- //

@media (max-width: 1500px) {

    html body .lds-layout2-right {
        // border: red 1px solid !important;
        // left: -368px;
        width: 1px;
        // min-width: 
        // position: relative;
    }

    html body .lds-layout2.is-panelmode-max .lds-layout2-right {
        width: 1px;
        left: -368px;
        min-width: 1px;
        max-width: 1px;
    }

    // html .lds-layout2.is-panelmode-min .lds-layout2-right {
    //     width: 50px;
    // }

    html body .lds-panel2 {
        box-shadow: -10px 0px 16px -4px rgba(16, 24, 40, 0.08), -4px 0px 6px -2px rgba(16, 24, 40, 0.03);
    }


}





// ========= MARGIN MODE TEST ========= //

.lds-layout2.is-marginmode-1  {

    .lds-layout2-main {
        padding-bottom: 70px;
    }
    .lds-layout2-right {
        padding-bottom: 70px;
    }
}

.lds-layout2.is-marginmode-2  {

    #lds-tasks3 {
        margin-bottom: 70px;
    }
}


