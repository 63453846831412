html {



    #lds-refills {
        background: 0 68px white repeat-x url(../../images/general/table-bg.png); 
        background-size: 100px 1276px; 
        width: 100%; 
        height: 816px;

        .lds-spriteleft,
        .lds-spriteright {
            background: repeat-x url(../../images/refills/refills.png); 
            background-size: 1072px 816px; 
            width: 750px; 
            height: 816px;
        }
        .lds-spriteright {
            background-position: top right;
            width: 200px;
            min-width: 200px; // make it stick
        }
    }

    // #lds-tasks-grid {
    //     background: 0 0 no-repeat url(../../images/tasks/tasks-grid.png); 
    //     background-size: 990px 591px; 
    //     width: 990px; 
    //     height: 591px;
    // }
    
    

}