// 296

html#lds-html-demos-msgdrill1 {
    height: 100% !important;
    background: repeat-y white url(../../images/demos/bottommargin1/body-bg.png); 
    overflow: hidden;
    padding-top: 72px;

    > body {
        height: 100% !important;
    }

    .lds-msgdrill1 {
        position: relative;
        top: 0;
        left: 0;
        display: flex;
        // flex-direction: column;
        width: 100%;
        min-height: 100%; // --- NEW
        // height: 100%;
        height: calc(100% - 72px);

        // border: red 10px solid;
    

        &-left {
            position: relative;
            width: 270px;
            min-width: 270px;
            overflow: hidden;
            // background-color: red;

            // msgdrill1-sidebar

            background: no-repeat url(../../images/demos/msgdrill1/msgdrill1-sidebar.png); 
            background-size: 270px 500px; 
        }

        &-patientlist {
            position: relative;
            width: 275px;
            min-width: 275px;
            overflow: hidden;
            background: no-repeat url(../../images/demos/msgdrill1/msgdrill1-content.png); 
            background-size: 1000px 1000px; 
            background-position: 24px 0;
        }

        &-middle {
            position: relative;
            top: 0;
            left: 0;
            display: flex;
            flex-grow: 1;
            // height: calc(100% - 62px);
            height: 100%;
            // overflow-x: hidden;
            // overflow-y: auto;
            // width: 270px;
            // min-width: 270px;
            // background-color: yellow;
            // border: teal 10px solid;
            // padding: 0 24px 100px;
            // background-color: blue;
            flex-direction: column;
        }

        &-middle-top {
            // height: 100px;
            // width: 100%;
            height: 140px;
            max-height: 140px;
            background-color: white;
            background: no-repeat url(../../images/demos/msgdrill1/msgdrill1-content.png); 
            background-size: 1000px 848px; 
            background-position: -255px 0;
        }

        &-middle-bottom {
            flex-grow: 1;
            // height: 100px;
            // width: 100%;
            // background-color: green;
            overflow-x: hidden;
            overflow-y: auto;
        }

        &-content {
            position: relative;
            top: 0;
            left: 0;
            background: no-repeat url(../../images/demos/msgdrill1/msgdrill1-content.png); 
            background-size: 1000px 1000px; 
            background-position: -250px -140px;
            width: 100%;
            height: 900px;
        }

        &-right {
            position: relative;
            width: 350px;
            min-width: 350px;
            // background-color: gray;
            // border-left: gray 1px solid;
            overflow-x: hidden;
            overflow-y: auto;
            // background-color: green;
        }

        &-panel {
            background: no-repeat url(../../images/demos/msgdrill1/msgdrill1-panel.png); 
            background-size: 368px 1628px; 
            width: 368px;
            height: 1628px;
        }


        // --- demos --- //

        // demo 1
        &.is-demo-1 {
            .lds-msgdrill1-content {
                background-image: url(../../images/demos/msgdrill1/msgdrill1-content-demo1.png); 

            }
        }

        // demo 2
        &.is-demo-2 {
            // --- WIP
        }
        
        // END CONTAINER
    }


    // END HTML
}







// --- responsive --- //

@media (max-width: 1500px) {

    html#lds-html-demos-detailspanel2 {

        // body .lds-detailspanel2.is-mode-max .lds-detailspanel2-right {
        //     width: 1px;
        //     max-width: 1px;
        //     min-width: 1px;
        // }

        // // body .lds-detailspanel2.is-mode-min .lds-detailspanel2-panel {
        // //     box-shadow: -10px 0px 16px -4px rgba(16, 24, 40, 0.08), -4px 0px 6px -2px rgba(16, 24, 40, 0.03);
        // // }

        // // body .lds-detailspanel2.is-mode-max .lds-detailspanel2-panel {
        // body .lds-detailspanel2 .lds-detailspanel2-panel {
        //     box-shadow: -10px 0px 16px -4px rgba(16, 24, 40, 0.08), -4px 0px 6px -2px rgba(16, 24, 40, 0.03);
        // }


    }

}


