html { // HTML





.lds-graphic {
    display: inline-block;
    width: 100px;
    height: 100px;
    background: no-repeat url(../images/graphics.png); 
    background-size: 400px 140px; 
    background-position: 0 0;

    // modifiers
    &.is-logo            {background-position: 0 0; width: 60px; height: 60px; }
}


    
            
            
            
} // HTML