html {


    #lds-videos {
        background: 0 -72px no-repeat url(../../images/videos/videos.png);
        background-size: 1440px 1024px; 
        width: 1440px; 
        height: 600px;
    }

    #lds-videos-list {
        background: 0 -72px no-repeat url(../../images/videos/videos-list.png);
        background-size: 1440px 1024px; 
        width: 1440px; 
        height: 600px;
    }

    #lds-videos-details {
        background: 0 -72px no-repeat url(../../images/videos/videos-details.png);
        background-size: 1440px 1178px; 
        width: 1440px; 
        height: 1070px;
    }




} // html