
/*
html .lds-phone {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    // display: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #555555;
    // background-color: rgba(255, 255, 255, .5);

    // modifiers
    &.is-open {
        display: flex;
    }
    
    // members
    &-wrapper {
        // width: 375px;
        // height: 600px;
        // border: red 1px solid;
        // overflow-x: hidden;
        // overflow-y: scroll;
        position: relative;
        top: 0;
        left: 0;
        width: 512px;
        height: 870px;
        background: url(../../images/general/iphone.png);
        background-size: 512px 933px;
        background-position: 0 -28px;
    }

    &-content {
        position: absolute;
        top: 28px;
        left: 63px;
        width: 375px;
        height: 812px;
        border-radius: 42px;
        overflow-x: hidden;
        overflow-y: scroll;
        // border: red 1px solid;
    }
}
*/


#phonetest1-home {
    background: no-repeat url(../../images/demos/phonetest1/home.png); 
    background-size: 375px 1606px; 
    width: 375px;
    height: 1606px;
}

#phonetest1-bottomsheet {
    background: no-repeat white url(../../images/demos/phonetest1/phonetest1-bottomsheet.png);
    background-size: 375px 153px;
    width: 375px;
    height: 153px;
}

#phonetest1-notifications {
    // background: no-repeat url(../../images/demos/phonetest1/home.png); 
    // background-size: 375px 1606px; 
    // background: no-repeat white url(../../images/demo/content/genphone-content-notifications.png);
    background: no-repeat white url(../../images/demos/phonetest1/phonetest1-notifications.png);
    background-size: 375px 826px;
    width: 375px;
    height: 826px;
}

#phonetest1-notifications-bottom {
    // background: no-repeat url(../../images/demos/phonetest1/home.png); 
    // background-size: 375px 1606px; 
    background: no-repeat white url(../../images/demos/phonetest1/phonetest1-notifications-bottom.png);
    background-size: 375px 98px;
    width: 375px;
    height: 98px;
}



html#lds-html-demos-phonetest1 {
    // background: repeat-y white url(../../images/demos/detailspanel1/body-bg.png); 
    height: 100% !important;
    overflow: hidden;

    body {
        height: 100% !important;
    }


    // END HTML
}


