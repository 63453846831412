html {


    #lds-messages-inbox {
        background: no-repeat url(../../images/messages/messages-inbox2.png); 
        background-size: 1000px 738px; 
        max-width: 1000px; 
        width: 100%;
        height: 738px;
    }

    #lds-messages-sidenav {
        background: no-repeat url(../../images/messages/messages-sidebar-chat.png); 
        background-size: 270px 500px; 
        width: 270px; 
        height: 500px;
    }
    #lds-messages-sidenav-send {
        background: no-repeat url(../../images/messages/messages-sidebar-bulk.png); 
        background-size: 270px 600px; 
        width: 270px; 
        height: 600px;
    }
    #lds-messages-sidenav-auto {
        background: no-repeat url(../../images/messages/messages-sidenav-auto.png); 
        background-size: 270px 500px; 
        width: 270px; 
        height: 500px;
    }

    #lds-messages-auto {
        background: no-repeat url(../../images/messages/messages-auto.png); 
        background-size: 1000px 900px; 
        width: 1000px; 
        height: 900px;
    }

    #lds-messages-auto2 {
        background: no-repeat url(../../images/messages/messages-auto2.png); 
        background-size: 1000px 1300px; 
        width: 1000px; 
        height: 1300px;
    }


    #lds-messages-send,
    #lds-messages-send-top {
        background: white repeat-x url(../../images/general/table-bg.png); 
        background-position: 0 68px;
        background-size: 100px 1276px; 
        width: 100%; 
        height: 423px;

        .lds-spriteleft,
        .lds-spriteright {
            background: url(../../images/messages/messages-send.png); 
            background-size: 1005px 423px; 
            width: 598px; 
            height: 423px;
        }
        .lds-spriteright {
            background-position: top right;
            width: 0;
            min-width: 160px; // make it stick
        }
    }

    #lds-messages-send-top {
        height: 112px;
        overflow: hidden;
    }

    #lds-messages-send-bottom {
        // display: none;
        position: relative;
        top: 0;
        left: 0;
        // height: 200px;
        height: 266px;
        overflow: hidden;
        // margin-top: -100px;
        // border: green 2px solid;
        transition: height .7s ease-out;
        transition-timing-function: cubic-bezier(0.16, 1, 0.3, 1);

        &.is-added {
            height: 310px;
        }

        #lds-messages-send {
            position: absolute !important;
            top: auto !important;
            bottom: 0 !important;
        }
    }


    #lds-messages-details {
        background: no-repeat url(../../images/messages/messages-details.png); 
        background-size: 800px 310px; 
        width: 800px; 
        height: 310px;
    }

    // #lds-messages-send-top {
    //     height: 113px;
    // }

    // #lds-messages-send-bottom {
    //     position: relative;
    //     top: 0;
    //     left: 0;
    //     height: 200px;
    //     overflow: hidden;
    // }

    // #lds-messages-send {
    //     background-position: 0 -44px;
    //     height: 309px;
    //     overflow: hidden;

    //     .lds-spriteleft,
    //     .lds-spriteright {
    //         background-position: 0 -112px;
    //     }
    // }


    #lds-messages-new-modal2 {
        background: no-repeat url(../../images/messages/messages-new-modal3.png); 
        background-size: 768px 1065px; // 768px 1092px; 
        width: 768px; 
        height: 1065px;
    }
    
    // previews
    #lds-messages-preview-chat,
    #lds-messages-preview-chat-nav {
        background: no-repeat url(../../images/messages/messages-preview-chat.png); 
        background-size: 768px 914px; 
        width: 768px; 
        height: 914px;
    }
    #lds-messages-preview-email,
    #lds-messages-preview-email-nav {
        background: no-repeat url(../../images/messages/messages-preview-email.png); 
        background-size: 768px 654px; 
        width: 768px; 
        height: 654px;
    }
    #lds-messages-preview-voice,
    #lds-messages-preview-voice-nav {
        background: no-repeat url(../../images/messages/messages-preview-voice.png); 
        background-size: 768px 358px; 
        width: 768px; 
        height: 358px;
    }
    #lds-messages-preview-sms,
    #lds-messages-preview-sms-nav {
        background: no-repeat url(../../images/messages/messages-preview-sms.png); 
        background-size: 768px 924px; 
        width: 768px; 
        height: 924px;
    }


    .lds-messages-preview-nav {
        position: absolute;
        top: auto;
        bottom: 72px;
        left: 50px;
        background-color: white;
        background-position: bottom center !important;
        width: 700px !important;
        height: 60px !important;
        z-index: 1;
    }

    // #lds-messages-sidenav-send {
    //     background: no-repeat url(../../images/messages/messages-sidenav-send.png); 
    //     background-size: 270px 500px; 
    //     width: 270px; 
    //     height: 500px;
    // }

    #lds-messages-panel { // --- WIP
        background: no-repeat url(../../images/messages/messages-panel.png); 
        background-size: 368px 1252px; 
        width: 368px; 
        height: 1252px;
    }

    #lds-messages-activity-panel { // --- WIP
        background: no-repeat url(../../images/messages/messages-activity-panel.png); 
        background-size: 368px 900px; 
        width: 368px; 
        height: 900px;
    }

    #lds-messages-convo {

        &-top {
            background-color: white;
            top: 40px;
            left: 0px;
            width: 100%;
            height: 300px;
            overflow: hidden;

            // new
            // display: flex;
            // overflow-x: hidden;
            // overflow-y: auto;

            // &-posit {
            //     position: absolute !important;
            //     top: 196px;
            //     left: 0;
            // }
        }

        &-thread {
            // position: relative !important;
            // top: 0;
            background: no-repeat url(../../images/messages/messages-convo.png); 
            background-size: 738px 600px; 
            width: 738px;
            height: 600px;

            transition: top .4s ease-out;
            transition-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
            top: 136px;
            // top: 36px;

            // // new
            // top: auto;
            // bottom: 0;
            // height: 100px; // 600px;


            &.is-count-1 {
                //height: 200px;
                top: 36px;
            }
            &.is-count-2 {
                //height: 300px;
                top: -44px;
            }
            &.is-count-3,
            &.is-count-4 {
                // height: 400px;
                top: -120px;
            }
        }

        .lds-input {
            top: 350px;
            left: 6px;
            width: 700px;

            .lds-input-field {
                font-size: 16px;
                font-weight: 400;
            }
        }


        .lds-spritebutton {
            top: auto;
            left: auto;
            bottom: 0;
            right: 0;
            width: 70px;
        }



    }


    // 

} // HTML