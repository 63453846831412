html {

    #lds-app-push-bg {
        background: url(../../images/app/general/push-bg.png); 
        background-size: 375px 812px; 
        width: 375px; 
        height: 812px;
    }

    // notifications
    #lds-app-push-notifications {
        background: url(../../images/app/general/push-notifications.png); 
        background-size: 375px 170px; 
        width: 375px; 
        height: 88px;
    }


    
} // html