html {


    .lds-menu {
        white-space: nowrap;
        cursor: pointer;
    }


    ul.lds-menulist {
        min-width: 200px;
        padding: 8px 0;
        border-radius: 4px;
    
    }

    li.lds-menuitem {
        position: relative;
        top: 0;
        left: 0;
        padding: 6px 16px 0;
        font-size: 14px;
        line-height: 13px;
        height: 32px;
        cursor: pointer;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        /* Text sm/Regular */
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;

        &.is-righticon {
            padding-right: 38px;
        }
    }
    
    .lds-menuitem {
    
    
        &-icon {
            position: absolute;
            top: 8px;
            left: 16px;
        }

        &-icon-right {
            position: absolute;
            top: 8px;
            left: auto;
            right: 16px;
        }

        // modifiers
        &.is-selected {
            color: #0093E8;
        }

        &.is-iconic {
            padding-left: 44px;
        }

        &:hover {
            background-color: #F5F9FD;
        }

        
    }

    .lds-menuitem-divider {
        padding: 8px 0;
        hr {
            border-width: 0;
            border-bottom: #E1EAEF 1px solid; // Gray 200
            margin: 0;
        }
    }
    
    
    
    } // HTML