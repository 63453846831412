html {


    // split
    #lds-patients {
        background: white repeat-x url(../../images/general/table-bg.png); 
        background-position: 0 72px;
        background-size: 100px 1276px; 
        width: 100%; 
        height: 820px;

        .lds-spriteleft,
        .lds-spriteright {
            background: url(../../images/patients/patients-list.png); 
            // background: 0 -72px url(../../images/forms/forms-subs2.png);
            background-size: 1025px 820px; 
            width: 650px; 
            height: 820px;
        }
        .lds-spriteright {
            background-position: top right;
            width: 0;
            min-width: 230px; // make it stick
        }
    }


    // #lds-patients-panel {
    //     background: repeat-x url(../../images/patients/patients-panel.png); 
    //     background-size: 368px 1252px; 
    //     width: 368px; 
    //     height: 1252px;
    // }

    #lds-patients-activity {
        background: no-repeat url(../../images/patients/patients-activity.png); 
        background-size: 1000px 645px; 
        width: 1000px; 
        height: 645px;
    }

    

    #lds-patients-panel {
        background: no-repeat url(../../images/patients/patients-panel.png); 
        background-size: 368px 1252px; 
        width: 368px; 
        height: 1252px;
    }

    #lds-patients-activity-panel {
        background: no-repeat url(../../images/patients/patients-activity-panel.png); 
        background-size: 368px 844px; 
        width: 368px; 
        height: 844px;
    }

    .lds-patients-panel-title {
        position: absolute;
        top: 120px;
        left: 24px;
        display: block;
        width: 270px;
        height: 30px;
        background-color: white;
        color: #101828;
        font-size: 24px;
        font-family: Lato;
        font-weight: 400;
        line-height: 26px;
    }

    // #lds-patients {
    //     background: url(../../images/patients/patients-list.png); 
    //     background-size: 1072px 900px; 
    //     width: 1072px; 
    //     height: 900px;
    // }

} // HTML