html { // HTML

path {
    stroke: #748995; // keep default here (for overriding)
}

.lds-icon-svg {
    display: inline-block;
    width: 20px;
    height: 20px;

    path {
        // stroke-width: 1.5;
        stroke-linecap: round;
        stroke-linecap: round;
        stroke-linejoin: round;
    }


    // modifiers
    &.is-size-sm { width: 16px; height: 16px; }

    &.is-size-lg {width: 24px; height: 24px; }

    &.is-size-xl {width: 28px; height: 28px; }

    &.is-size-xxl {
        width: 32px; 
        height: 32px; 
        path {stroke-width: 1;}
    }
}
    

// standard icon

.lds-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: no-repeat url(../../images/icons-md.png); 
    background-size: 1200px 140px; 
    background-position: 0 -20px;

    // modifiers
    &.is-size-sm {
        width: 16px; 
        height: 16px; 
        background: no-repeat url(../../images/icons-sm.png); 
        background-size: 1200px 140px; 
    }
    &.is-size-lg {
        width: 24px; 
        height: 24px; 
        background: no-repeat url(../../images/icons-lg.png); 
        background-size: 1200px 140px; 
    }

}



} // HTML