html {

    // for scrollTo
    height: auto !important;
    body {height: auto !important;}

    #lds-onboard1-design-button {
        text-align: center;
        padding-top: 20px;
        color: white;
        border-radius: 8px;
        /* opacity: 0.5; */
        /* display: none; */
        font-size: 14px;
        width: 347px;
        height: 58px;
        // bg color
        background-color: #0063B2;
        transition: background-color .8s ease-out;
    }

    .lds-onboard1-phonetop-shadow {
        position: relative;

        background: url(../../images/demos/ui/onboard-phonetop-shadow.png);
        background-size: 100%;
        width: 775px;
        height: 18px;
        // border: red 1px solid;
    }

    .lds-onboard-phonetop {
        position: relative;
        width: 418px;
        height: 300px;
        overflow: hidden;
        // background-color: green;
        // border: red 1px solid;
        > * {
            position: absolute;
            top: 0;
            left: 0;
            // border: red 1px solid;
        }

        // type - home
        &.is-type-home {
            .lds-onboard-phonetop-image {
                // background-image: url(../../images/demos/onboard1/onboard-phonetop-home2.png);
                background-image: url(../../images/demos/onboard1/onboard-phonetop-home.png);
            }
            &.is-loaded .lds-onboard-phonetop-image {
                background-image: url(../../images/demos/onboard1/onboard-phonetop-home.png);
            }

            .lds-onboard-phonetop-tabline,
            .lds-onboard-phonetop-tabtext,
            .lds-onboard-phonetop-title {
                display: none;
            }
            .lds-onboard-phonetop-bgcolor {
                height: 300px;
            }
        }

        // type - welcome
        &.is-type-welcome {
            .lds-onboard-phonetop-image {
                // background-image: url(../../images/demos/onboard1/onboard-phonetop-welcome2.png);
                background-image: url(../../images/demos/onboard1/onboard-phonetop-welcome.png);
            }
            &.is-loaded .lds-onboard-phonetop-image {
                background-image: url(../../images/demos/onboard1/onboard-phonetop-welcome.png);
            }
            .lds-onboard-phonetop-tabline,
            .lds-onboard-phonetop-tabtext,
            .lds-onboard-phonetop-title {
                display: none;
            }
            .lds-onboard-phonetop-bgcolor {
                height: 300px;
            }
        }

        &-image {
            background: url(../../images/demos/onboard1/onboard-phonetop-internal.png);
            background-size: 100%;
            width: 100%;
            height: 100%;
            z-index: 1;
            // background-color: blue;
        }

        &-bgcolor {
            top: 19px;
            left: 21px;
            width: 375px;
            height: 107px;
            background-color: #0063B2;
            transition: background-color .8s ease-out;
        }

        &-tabline {
            top: 183px;
            left: 22px;
            width: 184px;
            height: 4px;
            background-color: #0063B2;
            transition: background-color .8s ease-out;
            z-index: 2;
        }

        &-tabtext {
            top: 141px;
            left: 75px;
            font: 500 16px Lato;
            color: #0063B2;
            background-color: white;
            width: 200px;
            padding: 4px;
            z-index: 2;
            transition: color .8s ease-out;
        }

        &-title {
            top: 67px;
            left: 37px;
            font: 500 13px Lato;
            color: white;
            max-width: 340px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }



    .lds-onboard1 {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding-top: 72px;



        &-top {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 600px;
            text-align: center;
            // border: green 1px solid;
            padding-top: 30px;
            margin-bottom: 32px;

            .lds-demos-progressbar {
                margin-bottom: 20px;
            }
            
        }

        &-icon {
            background: url(../../images/demos/onboard1/onboard1-icons.png);
            background-size: 1000px 100px;
            width: 100px;
            height: 100px;
            margin-bottom: 22px;
    
            // types
            &.is-type-check {background-position: -100px 0;}
            &.is-type-clock {background-position: -200px 0;}
        }

        &-title {
            // color: #101828;
            color: #0063B2;
            font: 400 32px/34px Lato;
            text-align: center;
            margin-bottom: 8px;
        }

        &-description {
            color: var(--gray-700, #374D58);
            font: 400 18px/28px Lato;
            text-align: center;
        }

        &-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            // border: red 1px solid;
            // width: 500px;
            padding-bottom: 100px;
        }

        &-buttons {
            display: flex;
            // position: absolute;
            top: auto;
            bottom: 0;
            width: 350px;
            height: 44px;

            // &-inner {
            //     // position: relative !important;
            //     // top: 0;
            //     // left: 0;
            //     // margin: 0 auto;
            //     display: flex;
            //     width: 350px;
            //     height: 44px;
            //     // ={{display: 'flex', top: 'auto', bottom: 0, width: 350, height: 44, ...style, }}>
            // }

        }
        
    }

    // overrides

    .lds-page-demos .lds-mobile-button {
        display: none !important;
    }

    #lds-body-demos-onboard1- .lds-topnav-noauth {
        .lds-topnav-noauth-left,
        .lds-topnav-noauth-right {
            background-image: url(../../images/demos/ui/onboard-topnav.png);
            width: 340px;
        }
    }

    // components

    .lds-demos-progressbar {
        background: url(../../images/demos/ui/onboard-progress.png);
        background-size: 722px 560px;
        width: 722px;
        height: 70px;

        // steps
        &.is-step-1 {background-position-y: -70px;}
        &.is-step-2 {background-position-y: -140px;}
        &.is-step-3 {background-position-y: -210px;}
        &.is-step-4 {background-position-y: -280px;}
        &.is-step-5 {background-position-y: -350px;}
        &.is-step-6 {background-position-y: -420px;}
        &.is-step-7 {background-position-y: -490px;}
    }

    .lds-demos-progressbar2 { // --- NEW
        background: url(../../images/demos/ui/onboard-progress2.png);
        background-size: 722px 560px;
        width: 722px;
        height: 70px;

        // steps
        &.is-step-1 {background-position-y: -70px;}
        &.is-step-2 {background-position-y: -140px;}
        &.is-step-3 {background-position-y: -210px;}
        &.is-step-4 {background-position-y: -280px;}
        &.is-step-5 {background-position-y: -350px;}
        &.is-step-6 {background-position-y: -420px;}
        &.is-step-7 {background-position-y: -490px;}
    }


    // forms / email

    #lds-onboard1-email-singleowner {
        background: url(../../images/demos/onboard1/onboard1-email-singleowner.png);
        background-size: 100%;
        width: 955px;
        height: 663px;
    }

    #lds-onboard1-form-confirm {
        background: url(../../images/demos/onboard1/onboard1-form-confirm.png);
        background-size: 100%;
        width: 350px;
        height: 868px;
    }

    #lds-onboard1-form-hours {
        background: url(../../images/demos/onboard1/onboard1-form-hours.png);
        background-size: 100%;
        width: 568px;
        height: 1023px;
    }

    #lds-onboard1-form-password {
        background: url(../../images/demos/onboard1/onboard1-form-password.png);
        background-size: 100%;
        width: 469px;
        height: 481px;
    }

    #lds-onboard1-form-login {
        background: url(../../images/demos/onboard1/onboard1-form-login.png);
        background-size: 100%;
        width: 350px;
        height: 350px;
    }

    #lds-onboard1-form-welcome {
        background: url(../../images/demos/onboard1/onboard1-form-welcome.png);
        background-color: #eee; // TEMP
        background-size: 100%;
        width: 511px;
        height: 644px;
    }

    #lds-onboard1-form-account {
        background: url(../../images/demos/onboard1/onboard1-form-confirm.png);
        background-size: 100%;
        width: 350px;
        height: 868px;
    }

    #lds-onboard1-form-agree {
        background: url(../../images/demos/onboard1/onboard1-form-agree.png);
        background-size: 100%;
        width: 430px;
        height: 473px;
    }

    #lds-onboard1-form-branding {
        background: url(../../images/demos/onboard1/onboard1-form-branding.png);
        background-size: 100%;
        width: 350px;
        height: 1058px;
    }

    #lds-onboard1-form-brand {
        background: url(../../images/demos/onboard1/onboard1-form-brand.png);
        background-size: 100%;
        width: 1168px;
        height: 830px;
    }

    #lds-onboard1-form-brand2 {
        background: url(../../images/demos/onboard1/onboard1-form-brand.png);
        background-size: 1168px 830px;
        width: 1168px;
        height: 820px;
    }

    #lds-onboard1-form-departments {
        background: url(../../images/demos/onboard1/onboard1-form-departments.png);
        background-size: 100%;
        width: 350px;
        height: 406px;
    }

    #lds-onboard1-form-employees {
        background: url(../../images/demos/onboard1/onboard1-form-employees.png);
        background-size: 100%;
        width: 846px;
        height: 477px;
    }

    #lds-onboard1-form-patients {
        background: url(../../images/demos/onboard1/onboard1-form-patients.png);
        background-size: 100%;
        width: 842px;
        height: 1665px;
    }

    #lds-onboard1-form-completed {
        background: url(../../images/demos/onboard1/onboard1-form-completed.png);
        background-size: 100%;
        width: 511px;
        height: 670px;
    }

    // design

    #lds-onboard1-design-form2 {
        background: url(../../images/demos/onboard1/onboard1-design-form2.png);
        background-size: 100%;
        width: 631px;
        height: 3200px;
    }

    // GRAPHICS --- NEW
    .lds-onboard1-design-graphics {
        background: url(../../images/demos/onboard1/onboard1-design-graphics.png);
        background-size: 320px 691px;
        width: 320px;
        height: 120px; // 136px;
        background-position: 0 0;
        z-index: 1;

        // type
        &.is-type-box {
            background-position: 0 0;
        }
        &.is-type-invbox {
            background-position: 0 -113px;
        }
        &.is-type-logo {
            background-position: 0 -232px;
        }
        &.is-type-invlogo {
            background-position: 0 -348px;
        }
        &.is-type-icon {
            background-position: 0 -458px;
        }
        &.is-type-invicon {
            background-position: 0 -568px;
        }
    }

    // ICON --- NEW
    .lds-onboard1-design-iconbg {
        width: 162px;   
        height: 162px;
        border-radius: 24px;
        border: white 4px solid;
        background-color: #0162AF;
        // background-color: #EBEBEB;

        // &.is-dark {
        //     background-color: #0162AF;
        // }

        &.is-light {
            background-color: #ebebeb;
            top: 154px;
            left: 245px;
            width: 140px;
            height: 140px;
            border-width: 0;
        }
    }

    #lds-onboard1-design-form {
        background: url(../../images/demos/onboard1/onboard1-design-form.png);
        background-size: 100%;
        width: 717px;
        height: 480px;
    }

    #lds-onboard1-design-logo {
        background: url(../../images/demos/onboard1/onboard1-design-logo.png);
        background-size: 100%;
        width: 346.5px;
        height: 127px;
    }

    #lds-onboard1-design-logo-inverse {
        background: url(../../images/demos/onboard1/onboard1-design-logo-inverse.png);
        background-size: 100%;
        width: 346.5px;
        height: 127px;
    }

    // phone top (v2)

    .lds-design-carousel-item1 {

    }


    // carousel
    .lds-design-carousel {

        &-button {
            background: url(../../images/demos/ui/demos-chevrons.png);
            background-size: 148px 74px;
            width: 74px;
            height: 74px;

            &.is-next {
                background-position: -74px 0;
            }
        }
        
        // in (selection)
        &.is-in-0 .lds-design-carousel-item.is-index-0,
        &.is-in-1 .lds-design-carousel-item.is-index-1,
        &.is-in-2 .lds-design-carousel-item.is-index-2 {
            opacity: 1;
            z-index: 2;
            left: 146px; // centered
        }

        // out
        &.is-out-0 .lds-design-carousel-item.is-index-0,
        &.is-out-1 .lds-design-carousel-item.is-index-1,
        &.is-out-2 .lds-design-carousel-item.is-index-2 {
            left: 446px !important; // move right

            // &.is-reversed {
            //     left: -146px !important; // move left
            // }
        }

        &-item {
            top: 10px;
            left: -146px; // start left
            opacity: 0;
            transition: all .8s ease-out; // animation
            transition-timing-function: cubic-bezier(0.16, 1, 0.3, 1);

            &.is-reversed {
                left: 446px !important; // start right
            }
        }

        .lds-design-carousel-item.is-index-0 {
            // background: red none !important;
        }

        .lds-design-carousel-item.is-index-1 {
            // background: blue none !important;
        }

        .lds-design-carousel-item.is-index-2 {
            // background: green none !important;
        }
    }
    

} // html