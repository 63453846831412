html {


    .lds-email {
        background: repeat-x url(../../images/general/email-bg.png); 
        background-size: 20px 130px; 
        width: 100%; 
        min-width: 830px;
        height: 1031px;

        .lds-spriteleft,
        .lds-spriteright {
            background: repeat-x url(../../images/general/email.png); 
            background-size: 1440px 1031px; 
            width: 880px; 
            height: 1031px;
        }
        .lds-spriteright {
            background-position: top right;
            width: 200px;
            min-width: 200px; // make it stick
        }

        // title
        &-title {
            width: 700px;
            height: 40px;
            position: relative;
            top: 150px;
            left: 380px;
            background-color: white;
            // opacity: .5;

            font: 400 20px Lato;
            color: #555;
        }

        // content
        &-content {
            width: 800px;
            height: 500px;
            // border: red 1px solid;
            position: relative;
            top: 290px;
            left: 380px;
        }
    }

} // html