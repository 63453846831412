html {



    #lds-app-meds {
        background: url(../../images/app/meds/meds.png); 
        background-size: 375px 997px; 
        width: 375px; 
        height: 997px;
    }
    
    #lds-app-meds-orders {
        background: url(../../images/app/meds/meds-orders.png); 
        background-size: 375px 794px; 
        width: 375px; 
        height: 794px;
    }
    
    #lds-app-meds-reminders {
        background: url(../../images/app/meds/meds-reminders.png); 
        background-size: 375px 846px; 
        width: 375px; 
        height: 846px;
    }
    
    #lds-app-meds-details-modal {
        // background: url(../../images/app/meds/meds-details.png);
        // background-size: 375px 1221px; 
        // width: 375px; 
        // height: 1221px;
        background: url(../../images/app/meds/meds-details2.png);
        background-size: 375px 1422px; 
        width: 375px; 
        height: 1422px;
        margin-bottom: 40px;
    }
    
    #lds-app-meds-edsheet-modal {
        background: url(../../images/app/meds/meds-edsheet.png);
        background-size: 375px 812px; 
        width: 375px; 
        height: 812px;
        margin-bottom: 40px;
    }
    
    #lds-app-meds-ready { // --- new
        background: url(../../images/app/meds/meds-ready.png);
        background-size: 375px 724px; 
        width: 375px; 
        height: 724px;
    }
    
    #lds-app-meds-refill {
        background: no-repeat url(../../images/app/meds/meds-refill.png);
        background-size: 375px 851px; 
        background-position: 0 -88px;
        width: 375px; 
        height: 765px;
    
        // steps
        &-ins2 {
            background: url(../../images/app/meds/meds-refill-ins2.png);
            background-size: 375px 812px; 
            background-position: 0 -88px;
            width: 375px; 
            height: 700px;
        }
    
        &-info {
            background: url(../../images/app/meds/meds-refill-info.png);
            background-size: 375px 812px; 
            background-position: 0 -88px;
            width: 375px; 
            height: 700px;
        }
    
        &-method {
            background: url(../../images/app/meds/meds-refill-method.png);
            background-size: 375px 812px; 
            background-position: 0 -88px;
            width: 375px; 
            height: 700px;
        }
    
        &-review {
            background: url(../../images/app/meds/meds-refill-review2.png);
            background-size: 375px 1026px; 
            background-position: 0 -88px;
            width: 375px; 
            height: 938px;
        }
    
        &-confirm {
            background: url(../../images/app/meds/meds-refill-confirm2.png);
            background-size: 375px 812px; 
            background-position: 0 -88px;
            width: 375px; 
            height: 700px;
        }
    }
    
    // tabs
    #lds-app-meds-tabs {
        position: absolute;
        top: 108px;
        left: 0;
        width: 100%;
        height: 60px;
        z-index: 100;
        background: url(../../images/app/meds/meds-tabs.png); 
        background-size: 375px 180px; 

        &-inner {
            display: flex;
            width: 100%;
            height: 100%;
        }

        .lds-spritebutton {
            width: 33.33%;
            height: 100%;
        }

        &.is-tab-home {background-position: 0 0;}
        &.is-tab-orders {background-position: 0 -60px;}
        &.is-tab-reminders {background-position: 0 -120px;}

        // position: absolute;
        // top: 108px;
        // left: 0;
        // background: url(../../images/app/meds/meds-tabs.png); 
        // background-size: 375px 180px; 
        // width: 375px; 
        // height: 60px;
    }


    #lds-app-meds-refillagain-sheet {
        background: url(../../images/app/meds/meds-refillagain-sheet.png); 
        background-size: 375px 277px; 
        width: 375px; 
        height: 277px;
    }

    #lds-app-meds-refillagain-cards {
        background: url(../../images/app/meds/meds-refillagain-cards.png); 
        background-size: 375px 779px; 
        width: 375px; 
        height: 779px;
    }

} // html

