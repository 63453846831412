html {


    #lds-app-appts {
        background: url(../../images/app/appts/appts.png); 
        background-size: 375px 1026px; 
        width: 375px; 
        height: 1026px;

        &.is-2cards {
            background: url(../../images/app/appts/appts-2cards.png); 
            background-size: 375px 1225px; 
            width: 375px; 
            height: 1225px;
        }
    }


    #lds-app-appts-details {
        background: url(../../images/app/appts/appts-details.png); 
        background-size: 375px 1113px; 
        width: 375px; 
        height: 1113px;
    }

    // new

    #lds-app-appts-new-1 {
        background: url(../../images/app/appts/appts-new-1.png); 
        background-size: 375px 722px; 
        width: 375px; 
        height: 722px;
    }

    #lds-app-appts-new-2 {
        background: url(../../images/app/appts/appts-new-2.png); 
        background-size: 375px 854px; 
        width: 375px; 
        height: 854px;
    }

    #lds-app-appts-new-3 {
        background: url(../../images/app/appts/appts-new-3.png); 
        background-size: 375px 730px; 
        width: 375px; 
        height: 730px;
    }

    #lds-app-appts-new-4 {
        background: url(../../images/app/appts/appts-new-4.png); 
        background-size: 375px 711px; 
        width: 375px; 
        height: 711px;
    }

    #lds-app-appts-new-5 {
        background: url(../../images/app/appts/appts-new-5.png); 
        background-size: 375px 712px; 
        width: 375px; 
        height: 712px;
    }
    

}