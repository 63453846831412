html {

    // general



    #lds-demos-button {
        width: 100%;
        height: 1px;
        z-index: 1000;
        position: fixed; // absolute;
        bottom: 70px;
        left: 0;
        // border: red 1px solid;
        text-align: center;
        margin: 0 20px;

        // modifiers
        &.is-position-left {
            text-align: left;
        }

        // members
        &-inner {
            display: inline-block;
            // border: red 1px solid;
            // width: 100px;
            // height: 100px;
            padding: 10px;
            text-align: center;
            font-size: 16px;
            // background-color: white;
            // background-color: #0076ba;
            // background-color: #0093E8;
            background-color: white;
            // color: white;
            border-radius: 100px;
            // outline: rgba(255, 255, 255, .5) 3px solid;
            box-shadow: -3px 0px 24px 0px rgba(0, 0, 0, 0.25);
        }

        a {
            text-decoration: none;
            color: #00324f;
            // border-left: gray 1px solid;
            // padding: 0 10px;
            padding: 4px 10px;
            margin: 0 4px;
            background-color: white;
            border-radius: 100px;

            &.is-selected {
                // color: red;
                // color: #00a2ff;
                // font-weight: bold;
                background-color: #00a2ff;
                color: white;
            }


            &:first-child {
                border-left: white 1px solid;
            }

            svg {
                width: 16px;
                height: 16px;
                vertical-align: top;
                margin: 2px 0 0 4px;
                // color: white;
                path {
                    stroke: white;
                    stroke-width: 0;
                }
            }
        }
        span {
            display: inline-block;
            color: #b9b9b9;
            padding: 0 10px;
        }
    }

    #lds-demos-topbutton {
        display: inline-block;
        position: fixed;
        top: 10px;
        right: 10px;
        width: 180px;
        height: 50px;
        // border: red 1px solid;
        z-index: 1000;
    }

    #lds-demos-page {

        h1 {
            font: 300 normal 24px/26px Lato;
            color: #101828;
            margin-bottom: 0;
            margin-bottom: 24px;
        }
    
        h2 {
            // font: 600 16px/20px Lato;
            font: 600 15px/18px Lato;
            color: #101828;
            margin-bottom: 8px;
        }
    
        p {
            font: 600 14px/16px Lato;
            color: #748995;
            margin: 0 0 24px;
        }
    }

    .lds-demos {


        &-link {
            padding: 4px 0 8px;
            display: inline-block;

            &.is-nolink {
                color: #ccc;
                cursor: default;
            }
            // background-color: red;
        }


    }



    // hide topnav
    #lds-body-demos-onboard1 .lds-topnav {
        display: none;
    }
    
    // components
    #lds-demos-page {
        padding: 16px 24px;
    }


    #lds-demos-list {
        min-height: 400px;

        > div {
            color: #0093E8;
            // cursor: pointer;
            padding-bottom: 12px;
        }
    }

    .lds-demos-link {
        cursor: pointer;
    }

    #lds-demos-filepicker,
    #lds-demos-filepicker-selected {
        background: url(../../images/demos/ui/demos-filepicker.png);
        background-size: 100%;
        width: 803px; // 795px;
        height: 471px; // 410px;

        // // modifiers
        // &.is-selected {
        //     background-image: url(../../images/demos/ui/demos-filepicker-select.png);
        // }
    }

    #lds-demos-filepicker-selected {
        background-image: url(../../images/demos/ui/demos-filepicker-select.png);
    }



} // html