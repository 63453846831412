
.lds-topnav,
.lds-topnav-noauth {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    height: 72px;
    width: 100%;
    min-width: 1320px; // GLOBAL MIN WIDTH
    background: repeat-x url(../../images/toolbars/topnav-bg.png);
    background-size: 20px 72px;

    // modifiers
    &.is-brand {
        .lds-topnav-context {
            span {
                padding-left: 44px;
            }
        }
    }

    // members
    &-demo {
        position: absolute;
        left: auto;
        right: 0;
        display: inline-block;
        font-size: 13px;
        padding: 2px;
        color: white;
        background-color: rgb(255, 0, 0);
       //  width: 100px;
        z-index: 100;
    }

    &-logo {
        display: inline-block;
        position: relative;
        top: 0;
        left: 0;
        width: 90px;
        height: 72px;
        vertical-align: top;

        i {
            position: absolute;
            top: 10px;
            left: 20px;
        }
    }

    &-context {

        .lds-icon {
            position: absolute;
            top: 10px;
            left: 12px;
        }
        
        span {
            display: block;
            padding: 8px 0 0 18px;
            background-color: #00529f;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            color: white;
            font-size: 14px;
            font-family: Lato;
            line-height: 24px;
            font-weight: 400;
            // opacity: .5;
            width: 200px;
            border-radius: 8px;
        }

    }

    &-right {
        position: absolute;
        top: 0;
        left: auto;
        right: 0;
        background: top right url(../../images/toolbars/topnav.png);
        background-size: 1440px 72px;
        width: 480px;
        height: 72px;
        > * {
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    &-tab {
        display: inline-block;
        height: 72px;
        padding: 24px 16px 0;
        color: #CEECFF;
        cursor: pointer;
        text-decoration: none;

        &.is-selected {
            background-color: #0093E8;
            color: white;
        }
    }

    &-number {
        display: inline-block;
        border-radius: 11px;
        height: 22px;
        min-width: 22px;
        font-size: 12px;
        line-height: 18px;
        color: #374D58;
        background-color: #FFC854;
        text-align: center;
        padding: 2px 4px 0;
        margin-left: 10px;
    }
}

.lds-topnav-noauth {
    &-left,
    &-right {
        background: no-repeat top left url("../../images/toolbars/topnav-noauth.png");
        background-size: 1440px 72px;
        width: 300px;
        height: 72px;
    }
    &-right {
        background-position: top right;
    }
}

