


.lds-tabnav {
    position: absolute;
    top: auto;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 90px;
    z-index: 100;
    background: white url(../../images/app/general/tabnav.png);
    background-size: 375px 540px;

    &-tabs {
        display: flex;

        .lds-spritebutton {
            width: 20%;
            height: 70px;
        }
    }

    // modifiers
    &.is-tab-home {background-position: 0 -90px;}
    &.is-tab-meds {background-position: 0 -180px;}
    &.is-tab-messages {background-position: 0 -270px;}
    &.is-tab-appts {background-position: 0 -360px;}
    &.is-tab-account {background-position: 0 -450px;}
}