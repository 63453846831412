html { // HTML


    .lds-chatdots {
        position: absolute;
        top: 0;
        left: 0;
        width: 670px;
        height: 46px;
        background-color: white;

        // inner
        &-inner {
            position: absolute;
            top: 0;
            left: 0;
            width: 70px;
            height: 44px;
            background-color: #eaf1f4;
            border-radius: 0 8px 8px 8px;
        }

        .lds-dots {
            position: absolute;
            top: 16px;
            left: 16px;
        }

        &.is-phone {
            width: 340px;
            height: 89px; // 40px;

            .lds-chatdots-inner {
                background-color: #e1eaef;
                height: 36px;
            }
            
             .lds-dots {
                top: 12px !important;
            }

        }
    }



    // --- dots --- //

    @keyframes DotsFadeIn {
        0% { opacity: .3 }
        50% { opacity: 1 }
        100% { opacity: .3 }
    }
    
    .lds-dots {
        position: relative;
        top: 0;
        left: 0;
        width: 36px;
        height: 12px;
        

        // modifiers
        &.is-color-ltgray {background-color: #EAF1F4;}
        &.is-color-white {background-color: #ffffff;}

        // members
        div {
            position: absolute;
            width: 8px;
            height: 8px;
            border-radius: 100px;
            background-color: gray;

            top: 2px;
            left: 2px;
            opacity: 0;
            animation: DotsFadeIn 1500ms infinite;

            &:nth-child(2) {
                left: 14px;
                animation-delay: 500ms;
            }
            &:nth-child(3) {
                left: 26px;
                animation-delay: 1000ms;
            }
            
        }


    }
    
    
    } // HTML